import React from 'react'
import { Student } from './types'
import { Typography, Icon, Button, unstable_Tooltip as Tooltip } from '@design-system'
import SoraLink from '@components/link'

interface CellHouseProps extends Pick<Student, 'id' | 'currentHouseId' | 'currentHouseTitle' | 'currentHouseAdvisor' | 'status'> { }

export const CellHouse = ({ id, currentHouseId, currentHouseTitle, currentHouseAdvisor, status }: CellHouseProps) => {
  return (
    <div className="flex items-center gap-2 justify-between group/house">
      <div>
        <Typography variant="callout" asChild className="hover:underline">
          <SoraLink to={`/houses/${currentHouseId}`}>{currentHouseTitle}</SoraLink>
        </Typography>
        <Typography variant="callout" color="secondary">{currentHouseAdvisor}</Typography>
      </div>
      <Tooltip content="Edit house">
        <Button variant="ghost" size="xs" asChild color="soft" className="opacity-0 group-hover/house:opacity-100">
          <SoraLink to={`/employee/students/${id}/details/house-assignment`}>
            <Icon name="edit-3" size="xs" />
          </SoraLink>
        </Button>
      </Tooltip>
    </div>
  )
}
