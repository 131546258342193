import React from 'react'
import axios from 'axios'
import { Outlet, useLoaderData, useParams } from 'react-router'
import { Typography, Card, unstable_Tooltip as Tooltip, FetcherForm, Button, Icon } from '@design-system'
import { CardGraduationProgress, CardGraduationProgressVariant } from '@blocks/card-graduation-progress'
import SoraLink from '@components/link'
import { SquareAbilityMasteryLevel } from '@blocks/square-ability-mastery-level'
import serializeFormData from '@utils/serializeFormData'

interface LoaderData {
  graduationProgress?: {
    canEditOffTrack: boolean
    status: CardGraduationProgressVariant
    abilitiesStatus: CardGraduationProgressVariant
    percentage: number
    gpaForecast: number
    gpaTooltip: string
  }
  unitsProgress: {
    total: number
    completed: number
    completedPercentage: number
    completedUrl: string
    toComplete: number
    toCompleteUrl: string
  }
  subjectsUnitsProgress: {
    id: number
    title: string
    totalUnits: number
    completedUnits: number
  }[]
  abilitiesProgress: {
    id: number
    title: string
    average: number
    completions: number
    target: number
    potentialDemos?: number
    demos?: {
      demoNr: number
      status: SquareAbilityMasteryLevel['variant']
      level: number
      expeditionName: string
      cycle: string
    }[]
  }[]
  abilitiesLabels: Record<SquareAbilityMasteryLevel['variant'], string>
}

export async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/employee/students/${params.studentId}/program/${params.schoolStage}/overview?${searchParams.toString()}`)
  return data
}

async function action({ request, params }) {
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/employee/students/${params.studentId}/program/${params.schoolStage}/overview`, serializeFormData(formData))
  return data
}

function Element() {
  const studentId = useParams().studentId
  const { graduationProgress, unitsProgress, subjectsUnitsProgress, abilitiesProgress, abilitiesLabels } = useLoaderData() as LoaderData
  return (
    <>
      {graduationProgress && (
        <Card>
          <Card.Content>
            <header className="border-b pb-4 mb-4 border-b-gray-20 dark:border-b-gray-90">
              <Typography color="secondary">Progress</Typography>
              <Typography variant="heading-6" weight="bold">🎓  Graduation</Typography>
            </header>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 items-stretch">
              <CardGraduationProgress title='Units Progress' variant={graduationProgress.status}>
                {graduationProgress.canEditOffTrack && (
                  <GraduationStatusForm
                    status={graduationProgress.status}
                    type="unit"
                  />
                )}
              </CardGraduationProgress>
              <CardGraduationProgress title='Abilities Progress' variant={graduationProgress.abilitiesStatus}>
                {graduationProgress.canEditOffTrack && (
                  <GraduationStatusForm
                    status={graduationProgress.abilitiesStatus}
                    type="ability"
                  />
                )}
              </CardGraduationProgress>
              <Tooltip content={graduationProgress.gpaTooltip}>
                <Card actionable className="flex justify-between items-center grow h-full" asChild>
                  <a href="https://drive.google.com/file/d/1e9KU_GwS8_EE-o93R6PIrthuEvo3cRn6/view" target="_blank" rel="noreferrer noopener">
                    <Card.Content className="space-y-1">
                      <Typography>GPA Forecast</Typography>
                      <Typography variant="heading-5" weight="bold">{graduationProgress.gpaForecast}</Typography>
                    </Card.Content>
                    <Card.Arrow />
                  </a>
                </Card>
              </Tooltip>
              <Tooltip content="Percentage of completed units and abilities">
                <Card className="flex justify-between items-center grow h-full">
                  <Card.Content className="space-y-1">
                    <Typography>Program Progress</Typography>
                    <Typography variant="heading-5" weight="bold">{graduationProgress.percentage}%</Typography>
                  </Card.Content>
                </Card>
              </Tooltip>
            </div>
          </Card.Content>
        </Card>
      )}
      <Card>
        <Card.Content>
          <header className="flex flex-col md:flex-row md:justify-between gap-2 items-start md:items-end border-b pb-4 mb-4 border-b-gray-20 dark:border-b-gray-90">
            <div>
              <Typography color="secondary">Overview</Typography>
              <Typography variant="heading-6" weight="bold">🔢  Units</Typography>
            </div>
            <Typography>Total: {unitsProgress.total} units</Typography>
          </header>
          <div className="relative rounded-lg overflow-hidden">
            <div className="bg-cyan-10 dark:bg-cyan-60 absolute inset-0" style={{ width: `${unitsProgress.completedPercentage}%` }}></div>
            <div className="flex z-10 relative">
              <Card className="border-r-0 rounded-r-none bg-transparent dark:bg-transparent grow" asChild>
                <SoraLink to={unitsProgress.completedUrl} target="_blank">
                  <Card.Content>
                    <Typography>
                      Completed
                    </Typography>
                    <div className="flex gap-2">
                      <Typography variant="heading-5" weight="bold">
                        {unitsProgress.completed}
                      </Typography>
                    </div>
                  </Card.Content>
                </SoraLink>
              </Card>
              <Card className="border-l-0 rounded-l-none bg-transparent dark:bg-transparent grow" asChild>
                <SoraLink to={unitsProgress.toCompleteUrl} target="_blank">
                  <Card.Content className="text-right">
                    <Typography>
                      To complete
                    </Typography>
                    <Typography variant="heading-5" weight="bold">
                      {unitsProgress.toComplete}
                    </Typography>
                  </Card.Content>
                </SoraLink>
              </Card>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:flex gap-2 mt-4">
            {subjectsUnitsProgress.map(({ id, title, completedUnits, totalUnits }) => (
              <Card actionable asChild key={id} className="grow flex-1 shrink-0 gap-0">
                <SoraLink to={`../${id}`}>
                  <Card.Content className="flex flex-col justify-center gap-0 pr-0">
                    <Typography className="whitespace-nowrap">
                      {title}
                    </Typography>
                    <div className="flex gap-0.5 items-center">
                      <Typography variant="heading-5" weight="bold">
                        {completedUnits}
                      </Typography>
                      <Typography variant="footnote" weight="bold" className="whitespace-nowrap">
                        / {totalUnits}
                      </Typography>
                    </div>
                  </Card.Content>
                  <Card.Arrow />
                </SoraLink>
              </Card>
            ))}
          </div>
        </Card.Content>
      </Card>

      <Card>
        <Card.Content>
          <header className="flex flex-col md:flex-row md:justify-between gap-2 items-start md:items-end border-b pb-4 mb-4 border-b-gray-20 dark:border-b-gray-90">
            <div>
              <Typography color="secondary">Overview</Typography>
              <Typography variant="heading-6" weight="bold">🤸‍♀️ Abilities</Typography>
            </div>
          </header>
          <div className="rounded-lg border-b border-gray-20 dark:border-gray-100 -mx-6">
            {abilitiesProgress.map(({ id, title, average, completions, target, demos }) => (
              <SoraLink to={`abilities/${id}`} key={id} className="grid grid-cols-1 lg:grid-cols-[250px_1fr] gap-x-8 hover:bg-gray-10 dark:hover:bg-gray-95 px-6 py-2 cursor-pointer">
                <div className="flex flex-col justify-center">
                  <div className="flex gap-1 shrink-0">
                    <Typography weight="bold">{title}</Typography>
                    <Typography variant="footnote" className="align-super mt-0.5">({completions}/{target})</Typography>
                  </div>
                  <Typography variant="footnote">Average mastery: {average}</Typography>
                </div>
                <div className="flex gap-2 border-b dark:border-gray-85 last:border-b-0 py-3 overflow-auto lg:overflow-visible">
                  {demos?.map(({ demoNr, status, level, expeditionName, cycle }) => (
                    <Tooltip
                      key={demoNr}
                      delayDuration={0}
                      content={(
                        <div className="text-sm">
                          <p className="font-bold ">{level ? `${level} - ` : ''}{abilitiesLabels[status]}</p>
                          <p>{expeditionName}</p>
                          <p className="italic text-gray-70">{cycle}</p>
                        </div>
                      )}
                    >
                      <SquareAbilityMasteryLevel variant={status} level={level} />
                    </Tooltip>
                  ))}
                </div>
              </SoraLink>
            ))}
          </div>
          <div className="flex flex-wrap gap-3 justify-between mt-6">
            {Object.entries(abilitiesLabels).map(([variant, label]) => (
              <li key={variant} className="flex gap-2 items-center">
                <SquareAbilityMasteryLevel variant={variant as SquareAbilityMasteryLevel['variant']} size="sm" />
                <Typography>{label}</Typography>
              </li>
            ))}
          </div>
        </Card.Content>
      </Card>
      <Outlet />
    </>
  )
}

interface GraduationStatusFormProps {
  status: CardGraduationProgressVariant
  type: string
}

export const GraduationStatusForm: React.FC<GraduationStatusFormProps> = ({ status, type }) => (
  <FetcherForm method="POST" className="opacity-0 group-hover/card:opacity-100 mr-4">
    {({ fetcher }) => (
      <>
        <input type="hidden" name="type" value={type} />
        {status === 'off-track' ? (
          <Tooltip content={`Remove Off track`}>
            <Button name="markAsOfftrack" value="false" variant="ghost" loading={fetcher.state !== 'idle'}>
              <Icon name="cross-filled" />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip content={`Mark ${type.charAt(0).toUpperCase() + type.slice(1)} as Off track`}>
            <Button name="markAsOfftrack" value="true" variant="ghost" loading={fetcher.state !== 'idle'}>
              <Icon name="alert-octagon" />
            </Button>
          </Tooltip>
        )}
      </>
    )}
  </FetcherForm>
)

export const EmployeeStudentProgramOverviewRoute = {
  loader,
  action,
  Element,
}


