import React from 'react'
import { Student } from './types'
import { PillGraduationProgress, PillGraduationProgressVariant } from '@blocks/pill-graduation-progress'
import SoraLink from '@components/link'
import { Button, cn, FetcherForm, Icon, unstable_Tooltip as Tooltip } from '@design-system'

interface CellRequestListProps extends Pick<Student, 'graduationStatus' | 'graduationStatusUrl' | 'abilitiesGraduationStatus' | 'id' | 'canEditOffTrack'> { }

const StatusItem = ({
  type,
  status,
  url,
  studentId,
  canEditOffTrack
}: {
  type: 'unit' | 'ability',
  status: PillGraduationProgressVariant,
  url: string,
  studentId: number,
  canEditOffTrack: boolean
}) => (
  <div className={`flex gap-0.5 items-center group/progress`}>
    <SoraLink to={url}>
      <PillGraduationProgress prefix={type.charAt(0).toUpperCase() + type.slice(1)} variant={status} />
    </SoraLink>
    {canEditOffTrack && (
      <FetcherForm method="POST" className={cn("opacity-0 group-hover/progress:opacity-100")}>
        {({ fetcher }) => (
          <>
            <input type="hidden" name="studentId" value={studentId} />
            <input type="hidden" name="type" value={type} />
            {status === 'off-track' ? (
              <Tooltip content={`Remove Off track`}>
                <Button name="markAsOfftrack" value="false" size="xs" variant="ghost" loading={fetcher.state !== 'idle'}>
                  <Icon name="cross-filled" size="xs" />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip content={`Mark ${type.charAt(0).toUpperCase() + type.slice(1)} as Off track`}>
                <Button name="markAsOfftrack" value="true" size="xs" variant="ghost" loading={fetcher.state !== 'idle'}>
                  <Icon name="alert-octagon" size="xs" />
                </Button>
              </Tooltip>
            )}
          </>
        )}
      </FetcherForm>
    )}
  </div>
)

export const CellProgress = ({ graduationStatus, abilitiesGraduationStatus, graduationStatusUrl, id, canEditOffTrack }: CellRequestListProps) => {
  return (
    <div className='flex flex-col gap-1'>
      {graduationStatus && (
        <StatusItem
          type="unit"
          status={graduationStatus}
          url={graduationStatusUrl}
          studentId={id}
          canEditOffTrack={canEditOffTrack}
        />
      )}
      {abilitiesGraduationStatus && (
        <StatusItem
          type="ability"
          status={abilitiesGraduationStatus}
          url={graduationStatusUrl}
          studentId={id}
          canEditOffTrack={canEditOffTrack}
        />
      )}
    </div>
  )
}
