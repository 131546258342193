import React from 'react'
import { Student } from './types'
import { PillGraduationProgress } from '@blocks/pill-graduation-progress'
import SoraLink from '@components/link'

interface CellRequestListProps extends Pick<Student, 'graduationStatus' | 'graduationStatusUrl' | 'abilitiesGraduationStatus'> { }

export const CellProgress = ({ graduationStatus, abilitiesGraduationStatus, graduationStatusUrl }: CellRequestListProps) => {
  return (
    <>
       {graduationStatus && (
        <SoraLink to={graduationStatusUrl}>
          <PillGraduationProgress prefix='Units' variant={graduationStatus} />
        </SoraLink>
      )}
      {abilitiesGraduationStatus && (
        <div className={graduationStatus ? 'mt-2' : ''}>
          <SoraLink to={graduationStatusUrl}>
            <PillGraduationProgress prefix='Abilities' variant={abilitiesGraduationStatus} />
          </SoraLink>
        </div>
      )}
    </>
  )
}
