import React from 'react'
import axios from 'axios'
import { useLoaderData, ActionFunctionArgs } from 'react-router'
import serializeFormData from '@utils/serializeFormData'
import { Button, Combobox, Dialog, useFetcher } from '@design-system'
import { TextField } from '@designsystem'
import SoraLink from '@components/link'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'

interface LoaderData {
  title: string
  exemption: {
    exemptionId?: number
    subjectId?: string
    course: string
    school: string
    credit: string
    grade: string
    year: string
  }
  subjectOptions: { id: string, title: string }[]
  creditOptions: number[]
  gradeOptions: string[]
  yearOptions: number[]
}

export async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/employee/students/${params.studentId}/program/${params.schoolStage}/${params.subjectId}/external-elective/${params.topicId}?${searchParams.toString()}`)
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/employee/students/${params.studentId}/program/${params.schoolStage}/${params.subjectId}/external-elective/${params.topicId}`, serializeFormData(formData))
    .catch(error => {
      return {
        data: {
          toast: {
            appearance: 'error',
            message: error.response.data.error.message
          },
          errors: error.response.data.errors,
        },
      }
    })
  return data
}

function Element() {
  const { title, exemption, subjectOptions, creditOptions, gradeOptions, yearOptions } = useLoaderData() as LoaderData
  const fetcher = useFetcher()
  const formErrors = fetcher.data?.errors || []
  const navigate = useNavigateWithCycle()
  const handleClose = () => navigate(-1)
  return (
    <Dialog open onClose={handleClose}>
      <Dialog.Content>
        <fetcher.Form method="POST">
          <Dialog.Header>
            <Dialog.Title>{title}</Dialog.Title>
          </Dialog.Header>
          <input type="hidden" name="exemptionId" value={exemption.exemptionId} />
          <div className="py-8 grid grid-cols-3 gap-6">
            <Combobox className="col-span-full" name="subjectId" label="Subject" placeholder="Select subject" required defaultValue={exemption.subjectId} search={<Combobox.Search placeholder="Search..." />} error={formErrors.find(err => err.fieldName === 'subjectId')?.message}>
              {subjectOptions?.map(({ id, title }) => (
                <Combobox.Item key={id} value={id}>{title}</Combobox.Item>
              ))}
            </Combobox>
            <TextField
              required
              defaultValue={exemption.course}
              className="col-span-full"
              name="course"
              label="Transferred Course Name"
              placeholder="Add course name"
              error={formErrors.find(err => err.fieldName === 'course')?.message}
            />
            <TextField
              required
              defaultValue={exemption.school}
              className="col-span-full"
              name="school"
              label="School Name"
              placeholder="Add school name"
              error={formErrors.find(err => err.fieldName === 'school')?.message}
            />
            <TextField
              required
              defaultValue={exemption.credit}
              name="credit"
              label="Credits"
              as="select"
              error={formErrors.find(err => err.fieldName === 'credit')?.message}
            >
              <option value="">Select</option>
              {creditOptions.map(grade => (
                <option key={grade} value={grade}>{grade}</option>
              ))}
            </TextField>
            <TextField
              required
              defaultValue={exemption.grade}
              name="grade"
              label="Grade"
              as="select"
              error={formErrors.find(err => err.fieldName === 'grade')?.message}
            >
              <option value="">Select</option>
              {gradeOptions.map(grade => (
                <option key={grade} value={grade}>{grade}</option>
              ))}
            </TextField>
            <TextField
              required
              defaultValue={exemption.year}
              name="year"
              label="Year"
              as="select"
              error={formErrors.find(err => err.fieldName === 'year')?.message}
            >
              <option value="">Select</option>
              {yearOptions.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </TextField>
          </div>
          <Dialog.Footer>
            <Button variant="outlined" size="lg" asChild>
              <SoraLink to="..">
                Cancel
              </SoraLink>
            </Button>
            <Button type="submit" size="lg">Save</Button>
          </Dialog.Footer>
        </fetcher.Form>
      </Dialog.Content>
    </Dialog>
  )
}

export const EmployeeStudentProgramSubjectExternalElectiveRoute = {
  Element,
  loader,
  action
}


