import * as React from 'react'
import useSWR from 'swr'
import axios from 'axios'
import { ZodSchema } from 'zod'
import uniqueId from 'lodash/uniqueId'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import { captureException } from '@sentry/react'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'
import { Form, FormControl, FormField } from '@radix-ui/react-form'
import { Await, LoaderFunctionArgs, Form as RemixForm, useActionData, useLoaderData, useNavigate, useNavigation } from 'react-router'
import { TextField } from '@designsystem'
import { useToast } from '@hooks/useToast'
import { DurationInput } from './duration-input'
import { handleZodValidation } from './validation'
import { DateTimePicker } from './date-time-picker'
import serializeFormData from '@utils/serializeFormData'
import SlateTextarea from '@components/forms/slate-textarea'
import { FormProvider, useFormContext } from './form-context'
import { Combobox, useFormControlledCombobox } from './combobox'
import { useConfirmBeforeLeave } from '@hooks/useConfirmBeforeLeave'
import getBrowserTimezoneAbbreviation from '@utils/getTimezoneAbbreviation'
import { BlockContent, BlockTitle, BlockWrapper, FormDescription, SoraFormLabel, SoraFormMessage } from './primitives'
import { type ExperienceSession, type LabelValue, type LearningSection, type MaterialsAndTools, type Project, buildFormSchema } from './types'
import { unstable_Tooltip as Tooltip, Alert, Button, Combobox as DSCombobox, Dialog, Icon, Pill, unstable_RadioGroup as RadioGroup, unstable_Select as Select, Spinner, Typography, cn, FileUploader } from '@design-system'

type LoaderData = {
  units: LabelValueBySchoolStage,
  abilities: LabelValueBySchoolStage,
  employees: LabelValue[],
  categories: LabelValue[],
  schoolStage: LabelValue[],
  experienceTypes: LabelValue[],
  materialTypes: LabelValue[],
  defaultData: any,
  cycles: LabelValue[],
  isGeneratingConferenceAt: string,
  zoomIssues: string[]
  allGuidebooks: Guidebook[]
}

type Guidebook = {
  id: string,
  name: string,
  url: string,
}

type GuidebookFolder = {
  name?: string,
  childrenFiles: Guidebook[],
  childrenFolders: GuidebookFolder[],
  parentFolder?: GuidebookFolder,
}

type Toast = {
  type: 'success' | 'error',
  message: string,
  action?: { label: React.ReactNode, onClick: () => void }
}

type FormDataT = ReturnType<typeof buildFormSchema> extends ZodSchema<infer T> ? T : never
type ActionData = {
  errors?: Partial<Record<keyof FormDataT, unknown>>,
  redirectTo?: string,
  toast: Toast,
  resetFormDirty?: boolean,
}

async function getZoomIssues(resolvedUrl: string) {
  const url = `${resolvedUrl}?_loader=zoomIssues`
  const result = await axios.get(url)
  return result?.data
}

async function loader({ params }: LoaderFunctionArgs) {
  const experienceId = params.experienceId
  const resolvedUrl = `/backoffice/experiences/${experienceId ? `${experienceId}/edit` : 'edit'}`
  const { data } = await axios.get(resolvedUrl)
  return {
    ...data,
    zoomIssues: experienceId && getZoomIssues(resolvedUrl),
  }
}

async function action({ request, params }) {
  const experienceId = params.experienceId
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)

  const toastMessageVerb = { past: 'updated', present: 'updating' }

  const validationResult = handleZodValidation({
    data: serializedFormData, schema: buildFormSchema({
      enforceRequiredFields: !serializedFormData.is_draft,
      isExtracurricular: serializedFormData.type === 'extracurricular',
    })
  })
  if (validationResult.type === 'error') {
    return {
      errors: validationResult.error,
      toast: {
        message: 'An error ocurred during the validation of the fields. Please check the form and try again.',
        type: 'error',
      },
      resetFormDirty: false,
    }
  }
  try {
    const { data } = await axios.post(`/backoffice/experiences/${experienceId ? `${experienceId}/edit` : 'edit'}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    const experiencePreviewUrl = data.experienceInfo.previewUrl
    const createdExperienceId = data.experienceInfo?.experienceId

    return {
      ...(createdExperienceId ? { redirectTo: `../${createdExperienceId}/edit` } : {}),
      toast: {
        message: `Experience ${toastMessageVerb.past} successfully`,
        type: 'success',
        action: { label: <Button size="xs">Preview changes <Icon size="xs" name="external-link" /></Button>, onClick: () => window.open(experiencePreviewUrl, '_blank') }
      },
      resetFormDirty: true,
    }
  } catch (error) {
    captureException(error)
    return {
      toast: {
        message: `An error occurred while ${toastMessageVerb.present} the experience`,
        type: 'error',
      }
    }
  }
}

export function NEW_ExperienceFormRoute() {
  const loaderData = useLoaderData() as LoaderData
  const isISE = loaderData.defaultData.experience.type === 'independent_study'

  return (
    <div className="flex pt-4 pb-16 gap-8">
      <FormProvider>
        <SideNavigator isISE={isISE} />
        <ExperienceForm data={loaderData} isISE={isISE} />
      </FormProvider>
    </div>
  )
}

function ExperienceForm({ data, isISE = false }) {
  const actionData = useActionData() as ActionData
  const navigate = useNavigate()
  const [_, setFormData] = useFormContext()
  const errors = actionData?.errors
  const redirectTo = actionData?.redirectTo
  const resetFormDirty = actionData?.resetFormDirty

  const {
    units,
    abilities,
    employees,
    categories,
    schoolStage,
    experienceTypes,
    materialTypes,
    defaultData,
    cycles,
    isGeneratingConferenceAt,
    guidebooksGoogleDriveFolder,
  } = data

  const [isFormDirty, setFormDirty] = useConfirmBeforeLeave({ blockingConditional: ({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname && !redirectTo && isFormDirty.current })
  useToast({ toast: actionData?.toast })

  React.useEffect(() => {
    const formElement = document.getElementById('experience-form')
    if (formElement) {
      formElement.addEventListener('change', () => setFormDirty(true))
    }

    return () => formElement?.removeEventListener('change', () => setFormDirty(true))
  }, [])

  React.useEffect(() => {
    const firstErrorElement = document.getElementsByClassName('error-form-message')[0]
    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [errors])

  React.useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo)
    }
  }, [redirectTo])

  React.useEffect(() => {
    if (resetFormDirty) {
      setFormDirty(false)
    }
  }, [resetFormDirty])

  React.useEffect(() => {
    setFormData(() => {
      return {
        is_draft: String(defaultData?.isDraft ?? false),
        sessions: defaultData?.sessions,
        type: defaultData?.experience?.type,
        school_stage: defaultData?.experience?.schoolStage,
        units: defaultData?.units?.map((item) => item.id)?.toString(),
        abilities: defaultData?.abilities?.map((item) => item.id)?.toString(),
        employees: defaultData?.employees?.map((item) => item.employee_id)?.toString(),
        mainExpert: defaultData?.experience?.mainExpert?.employee_id?.toString(),
      }
    })
  }, [])

  return (
    <div className="flex-1">
      <React.Suspense>
        <Await resolve={data.zoomIssues}>
          {({ zoomMeetingIssues }) => (
            <>
              {zoomMeetingIssues.length > 0 && (
                <Alert variant="danger" className="my-6">
                  <Alert.Title>Zoom Meeting Issues</Alert.Title>
                  <Alert.Description>
                    <ul className="list-disc ml-4">
                      {zoomMeetingIssues.map((issue) => (
                        <li key={issue}>{issue}</li>
                      ))}
                    </ul>
                  </Alert.Description>
                </Alert>
              )}
            </>
          )}
        </Await>
      </React.Suspense>
      <Form asChild>
        <RemixForm id="experience-form" className="flex flex-col gap-6 w-full mb-12" method="POST" encType="multipart/form-data">
          <input type="hidden" name="is_redesigned_experience" value={isISE ? "false" : "true"} />
          <SettingsBlock
            defaultData={defaultData.experience}
            experts={employees}
            experienceTypes={experienceTypes}
            schoolStage={schoolStage}
            categories={categories}
            errors={errors}
            isGeneratingConferenceAt={isGeneratingConferenceAt}
            setFormDirty={setFormDirty} />
          <UnitsBlock units={units} />
          <AbilitiesBlock abilities={abilities} />
          <InstructorNotesBlock defaultData={defaultData.experience} setFormDirty={setFormDirty} />
          <ProjectsBlock defaultData={defaultData.projects} units={units} abilities={abilities} errors={errors} setFormDirty={setFormDirty} />
          <DetailsBlock defaultData={defaultData.experience} errors={errors} setFormDirty={setFormDirty} />
          {(defaultData.experience.type === 'independent_study') && <IseDetailsBlock defaultData={defaultData.experience} errors={errors} setFormDirty={setFormDirty} />}
          <EssentialQuestionsBlock defaultData={defaultData.experience} errors={errors} setFormDirty={setFormDirty} />
          <LearningObjectivesBlock defaultData={defaultData.experience} errors={errors} setFormDirty={setFormDirty} />
          <MaterialsAndToolsBlock defaultData={defaultData.materialsAndTools} materialTypes={materialTypes} errors={errors} setFormDirty={setFormDirty} />
          <LearningSectionsBlock defaultData={defaultData.sections} errors={errors} setFormDirty={setFormDirty} />
          <ExperienceExclusiveBlock cycles={cycles} errors={errors} defaultData={defaultData.experience} />
          <GuidebookBlock defaultData={defaultData.guidebook} guidebooksGoogleDriveFolder={guidebooksGoogleDriveFolder} />
          <SessionsBlock defaultData={defaultData.sessions} errors={errors} setFormDirty={setFormDirty} />
          <FinalDeliverableBlock defaultData={defaultData.finalDeliverable} units={units} abilities={abilities} errors={errors} setFormDirty={setFormDirty} />
          <FormFooter />
        </RemixForm>
      </Form>
    </div>
  )

}

interface LearningObjectivesBlockProps {
  defaultData?: { key_features_learning_objectives_description: any[] },
  setFormDirty: () => void,
  errors?: Pick<ActionData['errors'], 'key_features_learning_objectives_description'>
}

function LearningObjectivesBlock({ defaultData, errors, setFormDirty }: LearningObjectivesBlockProps) {
  const learingObjectivesRef = React.useRef(null)
  return (
    <BlockWrapper id="learning_objectives">
      <BlockTitle>Learning Objectives</BlockTitle>
      <BlockContent>
        <FormField className="w-full gap-1 flex flex-col" name="key_features_learning_objectives_description">
          <SoraFormLabel>Learning objectives description</SoraFormLabel>
          <SlateTextarea
            /* @ts-ignore: Unreachable code error */
            onChange={setFormDirty}
            id="key_features_learning_objectives_description"
            name="key_features_learning_objectives_description"
            ref={learingObjectivesRef}
            aria-label="key_features_learning_objectives_description"
            noAttachments={true}
            value={defaultData?.key_features_learning_objectives_description}
            className="h-48" />
        </FormField>
        {errors?.key_features_learning_objectives_description && <SoraFormMessage>Learning objectives description is required</SoraFormMessage>}
      </BlockContent>
    </BlockWrapper>
  )
}

interface SettingsBlockProps {
  experts: LabelValue[],
  categories: LabelValue[],
  schoolStage: LabelValue[],
  experienceTypes: LabelValue[],
  defaultData: {
    experts: string
    category: string
    schoolStage: string
    type: string
    isDraft: boolean
    mainExpert: { employee_id: string }
  },
  setFormDirty: () => void,
  errors?: Pick<ActionData['errors'], 'category' | 'type' | 'experts' | 'school_stage'>
  isGeneratingConferenceAt: string
}

function SettingsBlock({ experts, experienceTypes, categories, schoolStage, defaultData, errors, setFormDirty, isGeneratingConferenceAt }: SettingsBlockProps) {
  const [formData, setFormData] = useFormContext()
  const { handleAddParam, handleRemoveParam, notSelectedItems, selectedItems, selectedItemString } = useFormControlledCombobox({ values: experts, formKey: 'employees' })

  const handleTypeChange = (value: string) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        type: value
      }
    })
  }

  const handleSchoolStageChange = (value: string) => {
    setFormDirty()
    setFormData((prevState) => {
      if (value !== prevState?.school_stage) {
        return {
          ...prevState,
          units: '',
          abilities: '',
          school_stage: value
        }
      }
      return { ...prevState }
    })
  }

  const availableMainExperts = experts.filter((expert) => !selectedItems.includes(expert))
  const avaialbleCohosts = notSelectedItems.filter((expert) => formData?.mainExpert !== expert.value)

  return (
    <BlockWrapper id="template_settings">
      <BlockTitle>Experience Settings</BlockTitle>
      <BlockContent>
        <FormField className="w-full gap-2 flex items-center" name="is_draft">
          <FormControl asChild>
            <input
              type="checkbox"
              defaultChecked={defaultData?.isDraft ?? true}
            />
          </FormControl>
          <SoraFormLabel>Draft</SoraFormLabel>
        </FormField>
        <div className="gap-6 flex">
          <FormField className="w-full gap-1 flex flex-col" name="type">
            <SoraFormLabel>Type</SoraFormLabel>
            <FormControl asChild>
              <Select onValueChange={handleTypeChange} defaultValue={defaultData?.type}>
                <Select.Trigger className={errors?.type ? 'border-danger-40' : ''}>
                  <Select.Value placeholder="Select type"></Select.Value>
                </Select.Trigger>
                <Select.Content>
                  {experienceTypes.map((type) => (
                    <Select.Item key={type.value} value={type.value}>
                      {type.label}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            </FormControl>
            {errors?.type && <SoraFormMessage>Type is required</SoraFormMessage>}
          </FormField>
          {formData?.type === 'expedition' ? (
            <FormField className="w-full gap-1 flex flex-col" name="category" >
              <SoraFormLabel>Category</SoraFormLabel>
              <FormControl asChild>
                <Select defaultValue={defaultData?.category}>
                  <Select.Trigger className={errors?.category ? 'border-danger-40' : ''}>
                    <Select.Value placeholder="Select category"></Select.Value>
                  </Select.Trigger>
                  <Select.Content>
                    {categories.map((category) => (
                      <Select.Item key={category.value} value={category.value}>
                        {category.label}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              </FormControl>
              {errors?.category && <SoraFormMessage>Category is required</SoraFormMessage>}
            </FormField>
          ) : null}
        </div>

        <FormField className="w-full gap-1 flex flex-col" name="school_stage" >
          <SoraFormLabel>School Stage</SoraFormLabel>
          <FormControl asChild>
            <RadioGroup defaultValue={defaultData?.schoolStage ?? schoolStage[0].value} onValueChange={handleSchoolStageChange}>
              {schoolStage.map((stage) => (
                <RadioGroup.Item key={stage.value} value={stage.value} label={stage.label} />
              ))}
            </RadioGroup>
          </FormControl>
          {errors?.school_stage && <SoraFormMessage>School stage is required</SoraFormMessage>}
        </FormField>
        <FormField className="w-full gap-1 flex flex-col" name="mainExpert">
          <SoraFormLabel>Main Expert</SoraFormLabel>
          <div className="flex flex-col gap-2">
            <DSCombobox
              required
              name="mainExpert"
              placeholder="Type or select the main expert"
              search={<DSCombobox.Search placeholder="Search experts..." />}
              defaultValue={String(defaultData.mainExpert?.employee_id)}
            >
              {availableMainExperts.map((expert) => (
                <DSCombobox.Item key={`expert_${expert.value}`} value={expert.value}>{expert.label}</DSCombobox.Item>
              ))}
            </DSCombobox>
          </div>
        </FormField>
        <FormField className="w-full gap-1 flex flex-col" name="experts">
          <SoraFormLabel>Co-teachers</SoraFormLabel>
          <div className="flex flex-col gap-2">
            <div>
              <input type="hidden" name="experts" value={selectedItemString} />
              <Combobox options={avaialbleCohosts} placeholder="Type or select that will assist the main expert" onChange={handleAddParam}></Combobox>
              <FormDescription>Add here all experts that can conduct this experience. This field is not visible to students.</FormDescription>
              <FilterPills values={selectedItems} onRemove={handleRemoveParam} />
            </div>
          </div>
        </FormField>
        <FormField className="w-full gap-1 flex flex-col" name="conferenceUrl">
          <SoraFormLabel>Conference URL</SoraFormLabel>
          {/** @ts-ignore: can't type the zod error object deeply, so we use unknown and this may cause typing errors like the one on the line below */}
          <TextField readOnly value={isGeneratingConferenceAt ? 'Generating...' : defaultData?.conferenceUrl || 'Failed to generate'} error={!!errors?.conferenceUrl?.title} />
          {/** @ts-ignore: can't type the zod error object deeply, so we use unknown and this may cause typing errors like the one on the line below */}
          {errors?.conferenceUrl?.title && <SoraFormMessage>{errors?.conferenceUrl?.title ?? 'Conference URL is not set'}</SoraFormMessage>}
        </FormField>
      </BlockContent>
    </BlockWrapper>
  )
}

interface UnitsBlockProps {
  units: LabelValueBySchoolStage,
}

function UnitsBlock({ units }: UnitsBlockProps) {
  const currentUnits = useSchoolStageBasedValue(units)
  const { handleAddParam, handleRemoveParam, notSelectedItems, selectedItemString, selectedItems } = useFormControlledCombobox({ values: currentUnits, formKey: 'units' })

  return (
    <BlockWrapper id="units">
      <BlockTitle>Core Units</BlockTitle>
      <BlockContent>
        <div>
          <input type="hidden" name="units" value={selectedItemString} />
          <Combobox options={notSelectedItems} placeholder="Type to search for units" onChange={handleAddParam}></Combobox>
          <FormDescription>You can add multiple units.</FormDescription>
          <FilterPills values={selectedItems} onRemove={handleRemoveParam} />
        </div>
      </BlockContent>
    </BlockWrapper>
  )
}

interface AbilitiesBlockProps {
  abilities: LabelValueBySchoolStage,
}

function AbilitiesBlock({ abilities }: AbilitiesBlockProps) {
  const currentAbilities = useSchoolStageBasedValue(abilities)
  const { handleAddParam, handleRemoveParam, notSelectedItems, selectedItemString, selectedItems } = useFormControlledCombobox({ values: currentAbilities, formKey: 'abilities' })

  return (
    <BlockWrapper id="abilities">
      <BlockTitle>Core Abilities</BlockTitle>
      <BlockContent>
        <div>
          <input type="hidden" name="abilities" value={selectedItemString} />
          <Combobox options={notSelectedItems} placeholder="Type to search for ablilities" onChange={handleAddParam}></Combobox>
          <FormDescription>You can add multiple abilities.</FormDescription>
          <FilterPills values={selectedItems} onRemove={handleRemoveParam} />
        </div>
      </BlockContent>
    </BlockWrapper>
  )
}

interface InstructorNotesBlockProps {
  defaultData?: { instructor_notes: any[] },
  setFormDirty: () => void,
}

function InstructorNotesBlock({ defaultData, setFormDirty }: InstructorNotesBlockProps) {
  const instructorNotesRef = React.useRef(null)
  return (
    <BlockWrapper id="instructor_notes">
      <BlockTitle>Instructor notes</BlockTitle>
      <BlockContent>
        <FormField className="w-full gap-1 flex flex-col" name="instructor_notes">
          <SoraFormLabel>Instructor notes</SoraFormLabel>
          <SlateTextarea
            /* @ts-ignore: Unreachable code error */
            onChange={setFormDirty}
            id="instructor_notes"
            name="instructor_notes"
            ref={instructorNotesRef}
            aria-label="instructor_notes"
            noAttachments={true}
            value={defaultData?.instructor_notes}
            className="h-48" />
          <FormDescription>Add notes or relevant links for the instructor. Those won't be visible to students.</FormDescription>
        </FormField>
      </BlockContent>
    </BlockWrapper>
  )
}

interface DetailsBlockProps {
  defaultData?: {
    title: string,
    shortTitle?: string,
    rte_description: any[],
    image_url: string,
    image_alt: string,
    shortDescription?: string,
    video_section_video_url?: string
  },
  setFormDirty: () => void,
  errors?: Pick<ActionData['errors'], 'short_description' | 'title' | 'short_title' | 'image_alt' | 'image_url' | 'rte_description' | 'video_section_video_url'>
}

function DetailsBlock({ defaultData, errors, setFormDirty }: DetailsBlockProps) {
  const [formData] = useFormContext()
  const [shortTitleCharacterAmount, setShortTitleCharacterAmount] = React.useState(defaultData?.shortTitle?.length ?? 0)
  const [shortDescriptionCharacterAmount, setShortDescriptionCharacterAmount] = React.useState(defaultData?.shortDescription?.length ?? 0)

  return (
    <BlockWrapper id="experience_details">
      <BlockTitle>Experience details</BlockTitle>
      <FormField className="w-full gap-1 flex flex-col" name="title">
        <SoraFormLabel>Title</SoraFormLabel>
        <FormControl asChild>
          <TextField placeholder="Type the experience title" defaultValue={defaultData?.title} error={!!errors?.title} />
        </FormControl>
        {errors?.title && <SoraFormMessage>Title is required</SoraFormMessage>}
      </FormField>
      <FormField className="w-full gap-1 flex flex-col" name="short_title">
        <SoraFormLabel>
          <div className="flex justify-between w-full">
            <span>Short Title</span>
            <span className={`font-medium ${shortTitleCharacterAmount > 80 ? 'text-danger' : ''}`}>{shortTitleCharacterAmount}/80</span>
          </div>
        </SoraFormLabel>
        <FormControl asChild>
          <TextField placeholder="Type the short experience title" defaultValue={defaultData?.shortTitle} error={!!errors?.short_title} maxLength={80} onChange={(e) => setShortTitleCharacterAmount(e.target.value.length)} />
        </FormControl>
        {errors?.short_title && <SoraFormMessage>Short title is required</SoraFormMessage>}
      </FormField>
      <FormField className="w-full gap-1 flex flex-col" name="short_description">
        <SoraFormLabel>
          <div className="flex justify-between w-full">
            <span>Short Description</span>
            <span className={`font-medium ${shortDescriptionCharacterAmount > 500 ? 'text-danger' : ''}`}>{shortDescriptionCharacterAmount}/500</span>
          </div>
        </SoraFormLabel>
        <FormControl asChild>
          <textarea
            placeholder="short description"
            defaultValue={defaultData?.shortDescription}
            onChange={(e) => setShortDescriptionCharacterAmount(e.target.value.length)}
            maxLength={500}
            className={cn('resize-none overflow-y-auto h-48 border rounded-lg', errors?.short_description ? 'border-danger-40' : 'border-gray-40')} />
        </FormControl>
        {errors?.short_description && <SoraFormMessage>Short Description is required</SoraFormMessage>}
      </FormField>
      <div className="w-full gap-1 flex flex-col">
        <p className="text-sm font-bold">Description</p>
        <SlateTextarea
          /* @ts-ignore: Unreachable code error */
          onChange={setFormDirty}
          noAttachments
          value={defaultData?.rte_description}
          id="rte_description"
          aria-label="rte_description"
          name="rte_description"
          className="h-48"
        />
        {errors?.rte_description && <SoraFormMessage>Description is required</SoraFormMessage>}
      </div>
      <FileUploader urlFieldName="image_url" label="Cover Image" defaultValue={defaultData?.image_url ?? ''} error={errors?.image_url && 'Cover image is required'} name="image_file" accept="image/*" readOnly={false} onChangeCallback={setFormDirty}>
        <FileUploader.Title>Upload image</FileUploader.Title>
        <FileUploader.Description>Upload any image file type. File size no more than 5MB.</FileUploader.Description>
        <FileUploader.Actions>
          <Button variant="outlined" size="sm" asChild><p>Upload file</p></Button>
        </FileUploader.Actions>
      </FileUploader>
      <FormField className="w-full gap-1 flex flex-col" name="image_alt">
        <SoraFormLabel>Cover image alternative text</SoraFormLabel>
        <FormControl asChild>
          <TextField placeholder="Enter cover image alternative text" defaultValue={defaultData?.image_alt} error={!!errors?.image_alt} />
        </FormControl>
        <FormDescription>Provide a detailed description of the image, including all details required for someone to grasp its content solely through the description.</FormDescription>
        {errors?.image_alt && <SoraFormMessage>Cover image alternative text is required</SoraFormMessage>}
      </FormField>
      {formData?.type === 'activity'
        ? <input type="hidden" name="video_section_video_url" value={null} />
        : (
          <FormField className="w-full gap-1 flex flex-col" name="video_section_video_url">
            <SoraFormLabel>Expedition introduction video URL</SoraFormLabel>
            <FormControl asChild type="url" pattern="https://.*">
              <TextField placeholder="Paste video URL here" defaultValue={defaultData?.video_section_video_url} error={!!errors?.video_section_video_url} />
            </FormControl>
            {errors?.video_section_video_url && <SoraFormMessage>Video URL is required</SoraFormMessage>}
          </FormField>
        )}
    </BlockWrapper>
  )
}

interface IseDetailsBlockProps {
  defaultData?: {
    key_features_section_title: string,
    key_features_section_description: any[],
  },
  setFormDirty: () => void,
  errors?: Pick<ActionData['errors'], 'key_features_section_title' | 'key_features_section_description'>
}

function IseDetailsBlock({ defaultData, errors, setFormDirty }: IseDetailsBlockProps) {

  return (
    <BlockWrapper id="ise_details">
      <BlockTitle>ISE details</BlockTitle>
      <FormField className="w-full gap-1 flex flex-col" name="key_features_section_title">
        <SoraFormLabel>Title</SoraFormLabel>
        <FormControl asChild>
          <TextField placeholder="title" defaultValue={defaultData?.key_features_section_title} error={!!errors?.key_features_section_title} />
        </FormControl>
        {errors?.key_features_section_title && <SoraFormMessage>ISE Title is required</SoraFormMessage>}
      </FormField>
      <div className="w-full gap-1 flex flex-col">
        <p className="text-sm font-bold">Description</p>
        <SlateTextarea
          /* @ts-ignore: Unreachable code error */
          onChange={setFormDirty}
          noAttachments
          value={defaultData?.key_features_section_description}
          id="key_features_section_description"
          aria-label="key_features_section_description"
          name="key_features_section_description"
          className="h-48"
        />
        {errors?.key_features_section_description && <SoraFormMessage>ISE Description is required</SoraFormMessage>}
      </div>
    </BlockWrapper>
  )
}

interface EssentialQuestionsBlockProps {
  defaultData?: { key_features_essential_questions_description: any[] },
  setFormDirty: () => void,
  errors?: Pick<ActionData['errors'], 'key_features_essential_questions_description'>
}

function EssentialQuestionsBlock({ defaultData, errors, setFormDirty }: EssentialQuestionsBlockProps) {
  const essentialQuestionsRef = React.useRef(null)
  return (
    <BlockWrapper id="essential_questions">
      <BlockTitle>Essential Questions</BlockTitle>
      <BlockContent>
        <div className="w-full gap-1 flex flex-col">
          <p className="text-sm font-bold">Essential questions description</p>
          <SlateTextarea
            /* @ts-ignore: Unreachable code error */
            onChange={setFormDirty}
            noAttachments
            value={defaultData?.key_features_essential_questions_description}
            id="key_features_essential_questions_description"
            name="key_features_essential_questions_description"
            ref={essentialQuestionsRef}
            aria-label="key_features_essential_questions_description"
            placeholder="Type description here"
            className="h-48"
          />
          {errors?.key_features_essential_questions_description && <SoraFormMessage>Essential questions description is required</SoraFormMessage>}
        </div>
      </BlockContent>
    </BlockWrapper>
  )
}

interface MaterialsAndToolsBlockProps {
  defaultData?: MaterialsAndTools[]
  materialTypes: LabelValue[],
  setFormDirty: () => void,
  errors?: Pick<ActionData['errors'], 'materials'>
}

function MaterialsAndToolsBlock({ defaultData, materialTypes, errors, setFormDirty }: MaterialsAndToolsBlockProps) {
  const [materials, setMaterials] = React.useState<MaterialsAndTools[]>(defaultData ?? [])

  const handleAddMaterial = () => {
    setMaterials((prevState) => {
      return prevState.length > 0
        ? [...prevState, { id: uniqueId(), title: '', description: '', type: '' }]
        : [{ id: uniqueId(), title: '', description: '', type: '' }]
    })
  }

  const handleRemoveMaterial = (id: string) => {
    setMaterials((prevState) => {
      return [...prevState.filter((material) => material.id !== id)]
    })
  }

  return (
    <BlockWrapper id="materials_and_tools">
      <BlockTitle>Materials and Tools</BlockTitle>
      <BlockContent className="gap-10">
        {materials.map((material, index) => {
          return (
            <div className="flex flex-col gap-8" key={material.id}>
              <div className="flex w-full justify-between items-center">
                <BlockTitle>Material {index + 1}</BlockTitle>
                <Button variant="ghost" color="danger" size="sm" type="button" onClick={() => handleRemoveMaterial(material.id)}>
                  <Icon name="trash" size="xs"></Icon>
                  <span>Remove material</span>
                </Button>
              </div>
              <FormField className="w-full gap-1 flex flex-col" name={`materials[${index}][type]`}>
                <SoraFormLabel>Material type</SoraFormLabel>
                <FormControl asChild>
                  <Select defaultValue={material.type}>
                    <Select.Trigger className={errors?.materials?.[index]?.type ? 'border-danger-40' : ''}>
                      <Select.Value placeholder="Select material type"></Select.Value>
                    </Select.Trigger>
                    <Select.Content>
                      {materialTypes.map((type) => (
                        <Select.Item key={type.value} value={type.value}>
                          {type.label}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                </FormControl>
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`materials[${index}][title]`}>
                <SoraFormLabel>Material title</SoraFormLabel>
                <FormControl asChild>
                  <TextField defaultValue={material.title} error={!!errors?.materials?.[index]?.title} />
                </FormControl>
                {errors?.materials?.[index]?.title && <SoraFormMessage>Title is required</SoraFormMessage>}
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`materials[${index}][description]`}>
                <SoraFormLabel>Material description</SoraFormLabel>
                <SlateTextarea
                  /* @ts-ignore: Unreachable code error */
                  onChange={setFormDirty}
                  noAttachments
                  id={`material${material.id}_description`}
                  value={material.description}
                  name={`materials[${index}][description]`}
                  aria-label={`material${material.id}_description`}
                  placeholder="Type material description here"
                  className="h-48"
                />
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`materials[${index}][link]`}>
                <SoraFormLabel>Material link (optional)</SoraFormLabel>
                <FormControl asChild type="url" pattern="https://.*" >
                  <TextField defaultValue={material.link} />
                </FormControl>
              </FormField>
            </div>
          )
        })}
        <span className="self-center">
          <Button onClick={handleAddMaterial} type="button">Add Material</Button>
        </span>
      </BlockContent>
    </BlockWrapper>
  )
}

interface LearningSectionsBlockProps {
  defaultData?: MaterialsAndTools[],
  setFormDirty: () => void,
  errors?: Pick<ActionData['errors'], 'sections'>
}

function LearningSectionsBlock({ defaultData, errors, setFormDirty }: LearningSectionsBlockProps) {
  const [section, setSections] = React.useState<LearningSection[]>(defaultData ?? [])

  const handleAddSection = () => {
    setSections((prevState) => {
      return prevState.length > 0
        ? [...prevState, { id: uniqueId(), title: '', description: '' }]
        : [{ id: uniqueId(), title: '', description: '', }]
    })
  }

  const handleRemoveSection = (id: string) => {
    setSections((prevState) => {
      return [...prevState.filter((section) => section.id !== id)]
    })
  }

  return (
    <BlockWrapper id="learning_section">
      <BlockTitle>Six Week Plan</BlockTitle>
      <BlockContent className="gap-10">
        {section.map((section, index) => {
          return (
            <div className="flex flex-col gap-8" key={section.id}>
              <input type='hidden' name={`sections[${index}][index]`} value={index} />
              <div className="flex w-full justify-between items-center">
                <BlockTitle>Week {index + 1}</BlockTitle>
                <Button variant="ghost" color="danger" size="sm" type="button" onClick={() => handleRemoveSection(section.id)}>
                  <Icon name="trash" size="xs"></Icon>
                  <span>Remove week</span>
                </Button>
              </div>
              <FormField className="w-full gap-1 flex flex-col" name={`sections[${index}][title]`}>
                <SoraFormLabel>Week title</SoraFormLabel>
                <FormControl asChild>
                  <TextField defaultValue={section.title} error={!!errors?.sections?.[index]?.title} />
                </FormControl>
                {errors?.sections?.[index]?.title && <SoraFormMessage>Title is required</SoraFormMessage>}
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`sections[${index}][description]`}>
                <SoraFormLabel>Week description</SoraFormLabel>
                <SlateTextarea
                  /* @ts-ignore: Unreachable code error */
                  onChange={setFormDirty}
                  noAttachments
                  id={`section${section.id}_description`}
                  name={`sections[${index}][description]`}
                  aria-label={`section${section.id}_description`}
                  placeholder="Type section description here"
                  value={section.description}
                  className="h-48"
                />
              </FormField>
            </div>
          )
        })}
        <span className="self-center">
          <Button type="button" onClick={handleAddSection}>Add Week</Button>
        </span>
      </BlockContent>
    </BlockWrapper>
  )
}

function FormFooter() {
  const navigation = useNavigation()
  const isSubmitting = navigation.state === 'submitting'

  return (
    <div className="bg-white border-t border-t-1 items-center border-gray-40 px-40 h-16 w-full flex flex-row justify-end fixed bottom-0 left-0 right-0 z-10">
      <div className="space-x-2">
        <Button size="sm" variant="outlined" onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          window.history.back()
        }}>
          Cancel
        </Button>
        <Button
          size="sm"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? <Icon size="sm" name="loader" className="animate-spin" /> : 'Save'}
        </Button>
      </div>
    </div>
  )
}

interface GoogleDriveFileProps {
  file: Guidebook,
  onDismiss?: () => void,
  navigateToUrl?: boolean,
}

function GoogleDriveFile({ file, onDismiss, navigateToUrl }: GoogleDriveFileProps) {
  const title = <Typography variant='body' weight='bold'>
    {file.name}
  </Typography>

  return <div className='p-2 rounded-lg border border-gray-50 flex'>
    <div className='p-4 rounded-lg bg-gray-10 flex-none'>
      <div className='size-6'>
        <img src="/assets/google-document.svg" className='w-6 h-6' alt="Document" />
      </div>
    </div>
    <div className='flex-1 content-center ps-4'>
      {navigateToUrl ? <a href={file.url} target="_blank">
        {title}
      </a> : title}
      <Typography variant='footnote'>
        Google Docs
      </Typography>
    </div>
    {onDismiss && (
      <div className='flex-none content-center'>
        <Button
          variant='ghost'
          onClick={onDismiss}>
          <Icon name='cross' />
        </Button>
      </div>
    )}
  </div>
}

interface GuidebookBlockProps {
  defaultData?: Guidebook,
  guidebooksGoogleDriveFolder: string,
}

function GuidebookBlock({ defaultData, guidebooksGoogleDriveFolder }: GuidebookBlockProps) {
  const [selectedFile, setSelectedFile] = React.useState(defaultData)

  return (
    <BlockWrapper id="guidebook">
      <BlockTitle>Expedition Guide</BlockTitle>
      <BlockContent>
        <input type="hidden" name="guidebook_document_id" value={selectedFile?.id ?? ''} />
        <input type="hidden" name="guidebook_document_url" value={selectedFile?.url ?? ''} />
        <input type="hidden" name="guidebook_document_name" value={selectedFile?.name ?? ''} />
        <Typography variant="callout">
          Each student will get their own copy of the file with their name added to the document title. They will be able to access it from each individual task that uses the expedition guide.
        </Typography>
        <Typography variant="callout" weight="bold">
          Important: Make sure your documents are in the <a className='hover:underline text-accent' target='_blank' href={`https://drive.google.com/drive/folders/${guidebooksGoogleDriveFolder}`}>guidebooks folder</a> before you insert them.
        </Typography>
        <div>
          {
            selectedFile ?
              <GoogleDriveFile file={selectedFile} navigateToUrl={true} onDismiss={() => setSelectedFile(null)}
              /> :
              <Dialog>
                <Dialog.Trigger asChild>
                  <Button variant="outlined" color="soft" type="button">
                    <img src="/assets/google-drive.svg" className='w-6 h-6' alt="Google Drive" />
                    <span>Insert from Google Drive</span>
                  </Button>
                </Dialog.Trigger>
                <GuidebookDialogContent
                  defaultData={selectedFile}
                  setSelectedFile={setSelectedFile}
                />
              </Dialog>
          }
        </div>
      </BlockContent>
    </BlockWrapper>
  )
}

interface GuidebookDialogContentProps {
  defaultData?: Guidebook,
  setSelectedFile: (file: Guidebook) => void,
}

function GuidebookDialogContent({ defaultData, setSelectedFile }: GuidebookDialogContentProps) {
  const { isOpen } = Dialog.useDialog()
  if (!isOpen) {
    return null
  }

  return (
    <GuidebookDialogOpenedContent
      defaultData={defaultData}
      setSelectedFile={setSelectedFile}
    />
  )
}

function GuidebookDialogOpenedContent({ defaultData, setSelectedFile }: GuidebookDialogContentProps) {
  const { closeDialog } = Dialog.useDialog()
  const [rootFolder, setRootFolder] = React.useState<GuidebookFolder>(null)
  const [currentFolderState, setCurrentFolderState] = React.useState(null)
  const { mutate: refreshGuidebooks } = useSWR('/backoffice/experiences/guidebooks', {
    onSuccess: (data) => {
      setRootFolder(data)
      setCurrentFolderState(data)
    }
  })
  const [dialogSelectedFile, setDialogSelectedFile] = React.useState(defaultData)
  const searchInputRef = React.useRef(null)
  const backToRootFolder = React.useCallback(() => {
    setCurrentFolderState(rootFolder)
  }, [rootFolder])
  const handleClearSearch = React.useCallback(() => {
    searchInputRef.current.value = ''
    backToRootFolder();
  }, [searchInputRef, rootFolder])

  const setSelectedFolder = React.useCallback((folder: GuidebookFolder) => {
    setDialogSelectedFile(null)
    setCurrentFolderState(folder)
  }, [currentFolderState])
  React.useEffect(() => {
    function setParentFolderForChildren(folder: GuidebookFolder) {
      if (!folder) {
        return
      }
      for (const childFolder of folder.childrenFolders ?? []) {
        childFolder.parentFolder = folder
        setParentFolderForChildren(childFolder)
      }
    }
    setParentFolderForChildren(rootFolder)
  }, [rootFolder])
  const folderStack: GuidebookFolder[] = React.useMemo(() => {
    function getFolderStack(folder: GuidebookFolder) {
      if (!folder) {
        return []
      }
      if (!folder.parentFolder) {
        return [folder]
      }

      return [...getFolderStack(folder.parentFolder), folder]
    }

    return getFolderStack(currentFolderState)
  }, [currentFolderState])

  return (
    <Dialog.Content className='h-[70svh]'>
      <Dialog.Header variant="outlined">
        <Dialog.Title>Insert Guidebook</Dialog.Title>
        <Dialog.Close />
      </Dialog.Header>
      {
        rootFolder ?
          <>
            <Dialog.Body className='px-8 pt-8 gap-0 overflow-y-auto h-full'>
              <TextField
                ref={searchInputRef}
                startAdornment={<Icon name="search" size="sm" />}
                placeholder="Search guidebook..."
                name='search'
                endAdornment={
                  <Button variant="ghost" size="sm" className="text-alpha/40" type="button" onClick={handleClearSearch}>
                    <Icon name="cross-circle" />
                  </Button>
                }
                autoComplete='off'
                onChange={(e: { target: { value: string } }) => {
                  const query = e.target.value.toLowerCase()
                  if (query === '') {
                    backToRootFolder();
                    return
                  }

                  function getFiles(folders: { childrenFiles?: Guidebook[], childrenFolders?: GuidebookFolder[] }[]): Guidebook[] {
                    return folders.flatMap((folder) => [
                      ...(folder.childrenFiles ?? []),
                      ...getFiles(folder.childrenFolders ?? []),
                    ])
                  }
                  const allFiles = getFiles([rootFolder])
                  setCurrentFolderState({
                    name: '...',
                    childrenFiles: allFiles.filter((guidebook) => guidebook.name.toLowerCase().includes(query)),
                    childrenFolders: [],
                  })
                }}
                fullWidth
                autoFocus
              />
              <div className='flex items-center gap-2'>
                <Typography className='my-6' variant='subheadline' weight='bold'>Select Guidebook</Typography>
                <Tooltip content='Refresh'>
                  <Button variant='ghost' size='sm' onClick={() => {
                    setRootFolder(null)
                    setSelectedFolder(null)
                    refreshGuidebooks()
                  }}>
                    <Icon name='refresh-cw' />
                  </Button>
                </Tooltip>
              </div>
              {folderStack.length > 1 ? <div className='flex mb-6'>
                {folderStack.map((folder, index) => (
                  <FolderStackItem
                    folder={folder}
                    isLast={index === folderStack.length - 1}
                    setSelectedFolder={setSelectedFolder}
                  />
                ))}
              </div> : null}
              {currentFolderState.childrenFolders?.length ?? 0 ? <div className='grid grid-cols-2 gap-4 mb-6'>
                {currentFolderState.childrenFolders.map((folder) => (
                  <div onClick={() => {
                    setSelectedFolder(folder)
                  }}
                    className='cursor-pointer rounded-lg bg-gray-10 flex px-2 py-3'>
                    <Icon name='folder' className='mr-4' />
                    <Typography variant='body' weight='bold'>{folder.name}</Typography>
                  </div>
                ))}
              </div> : null}
              {currentFolderState.childrenFiles?.map((guidebook) => (
                <div onClick={() => {
                  setDialogSelectedFile(guidebook)
                }}
                  className={`cursor-pointer flex px-2 py-3 border-b border-b-gray-30 ${dialogSelectedFile?.id === guidebook.id ? 'bg-blue-5' : ''}`}>
                  <Icon name='file-text' className='mr-4' />
                  <Typography variant='body' weight='bold'>{guidebook.name}</Typography>
                </div>
              ))}
            </Dialog.Body>
            <Dialog.Footer >
              <Button
                variant='outlined'
                onClick={() => {
                  setDialogSelectedFile(null)
                  backToRootFolder()
                  closeDialog()
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!dialogSelectedFile}
                onClick={() => {
                  setSelectedFile(dialogSelectedFile)
                  setDialogSelectedFile(null)
                  backToRootFolder()
                  closeDialog()
                }}
              >
                Insert
              </Button>
            </Dialog.Footer>
          </> :
          <Dialog.Body className='flex justify-center items-center h-full'>
            <Spinner />
          </Dialog.Body>
      }
    </Dialog.Content>
  )
}
interface FolderStackItemProps {
  folder: GuidebookFolder,
  isLast: boolean,
  setSelectedFolder: (folder: GuidebookFolder) => void,
}

function FolderStackItem({ folder, isLast, setSelectedFolder }: FolderStackItemProps) {
  return (
    <>
      <Typography
        className={!isLast ? 'cursor-pointer' : ''}
        onClick={!isLast ? () => {
          setSelectedFolder(folder)
        } : null}
        variant='subheadline'
        weight={isLast ? 'bold' : 'regular'}
      >{folder.name ?? '...'}</Typography>
      {!isLast && (
        <Icon name='chevron-right' className='mx-1' />
      )}
    </>
  )
}

interface FinalDeliverableBlockProps {
  units: LabelValueBySchoolStage,
  abilities: LabelValueBySchoolStage,
  defaultData?: {
    id: string,
    title: string,
    body: any[],
    dueAt: string,
    themeSessionId?: string,
    isHidden: boolean
  },
  setFormDirty: () => void,
  errors?: Pick<ActionData['errors'], 'final_deliverable'>
}

function FinalDeliverableBlock({ defaultData, units, abilities, errors, setFormDirty }: FinalDeliverableBlockProps) {
  const [formData] = useFormContext()
  const [isAttachedToNewSession, setIsAttachedToNewSession] = React.useState(false)
  const [dueAt, setDueAt] = React.useState(defaultData?.dueAt || new Date().toISOString())
  const isExtracurricularExperience = formData?.type === 'extracurricular'
  const [isFinalDeliverableVisible, setIsFinalDeliverableVisible] = React.useState(isExtracurricularExperience && !defaultData ? false : true)

  const currentUnits = useSchoolStageBasedValue(units)
  const currentAbilities = useSchoolStageBasedValue(abilities)
  const { selectedItems: finalReviewUnits } = useFormControlledCombobox({ values: currentUnits, formKey: 'units' })
  const { selectedItems: finalReviewAbilities } = useFormControlledCombobox({ values: currentAbilities, formKey: 'abilities' })

  const sessionsOptions = formData?.sessions?.map((session, index) => {
    return { value: (session.id ? session.id : session.localId).toString(), label: `Session ${index + 1}` }
  }) ?? []
  sessionsOptions.unshift({ value: '-1', label: 'None' })

  const handleUpdateSession = (sessionId: string) => {
    const attachedSession = sessionId === '-1' ? null : formData?.sessions?.find((session) => String(session.localId) === sessionId || String(session.id) === sessionId)
    setIsAttachedToNewSession(attachedSession?.localId === sessionId)
    setDueAt(attachedSession?.timestamptz ?? new Date().toISOString())
  }

  React.useEffect(() => {
    if (formData?.type === 'extracurricular' && !defaultData) {
      setIsFinalDeliverableVisible(false)
    }
  }, [formData?.type])


  return (
    <BlockWrapper id="final_project" unstyled>
      {isFinalDeliverableVisible && (
        <BlockWrapper>
          {!isExtracurricularExperience ?
            <BlockTitle>Final project</BlockTitle>
            : (
              <div className="flex w-full justify-between items-center">
                <BlockTitle>Final Project</BlockTitle>
                <Button variant="ghost" color="danger" size="sm" type="button" onClick={() => setIsFinalDeliverableVisible(false)}>
                  <Icon name="trash" size="xs"></Icon>
                  <span>Remove</span>
                </Button>
              </div>
            )
          }
          <BlockContent>
            <input type="hidden" value={defaultData?.id} name="final_deliverable[id]" />
            <input type="hidden" value="final" name="final_deliverable[type]" />
            <input type="hidden" value="false" name="final_deliverable[is_readonly]" />
            <input type="hidden" value={String(isAttachedToNewSession)} name="final_deliverable[is_attached_session_new]" />
            <input type="hidden" value={dueAt} name="final_deliverable[due_at]" />
            <FormField className="w-full gap-1 flex flex-col" name="final_deliverable[title]">
              <SoraFormLabel>Final project title</SoraFormLabel>
              <FormControl asChild>
                {/** @ts-ignore: can't type the zod error object deeply, so we use unknown and this may cause typing errors like the one on the line below */}
                <TextField placeholder="Enter final deliverable title" defaultValue={defaultData?.title} error={!!errors?.final_deliverable?.title} />
              </FormControl>
              {/** @ts-ignore: can't type the zod error object deeply, so we use unknown and this may cause typing errors like the one on the line below */}
              {errors?.final_deliverable?.title && <SoraFormMessage>{errors?.final_deliverable?.title ?? 'Title is required'}</SoraFormMessage>}
            </FormField>
            <FormField className="w-full gap-1 flex flex-col" name="final_deliverable[theme_session_id]">
              <SoraFormLabel>Attach to session</SoraFormLabel>
              <FormControl asChild>
                <Select defaultValue={defaultData?.themeSessionId ?? '-1'} onValueChange={handleUpdateSession}>
                  <Select.Trigger>
                    <Select.Value placeholder="Attach to session"></Select.Value>
                  </Select.Trigger>
                  <Select.Content>
                    {sessionsOptions.map((session) => (
                      <Select.Item key={session.value} value={session.value}>
                        {session.label}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              </FormControl>
            </FormField>
            <FormField className="w-full gap-1 flex flex-col" name="final_deliverable[body]">
              <SoraFormLabel>Task Description</SoraFormLabel>
              <SlateTextarea
                /* @ts-ignore: Unreachable code error */
                onChange={setFormDirty}
                noAttachments
                value={defaultData?.body}
                id="final_deliverable_description"
                aria-label="final_deliverable_description"
                name="final_deliverable[body]"
                className="h-48"
              />
            </FormField>
            <div>
              <div className="flex">
                <FormField className="gap-2 flex items-center justify-center" name="final_deliverable[is_hidden]">
                  <FormControl asChild>
                    <input type="checkbox" defaultChecked={defaultData?.isHidden} />
                  </FormControl>
                  <SoraFormLabel>Hidden Task</SoraFormLabel>
                </FormField>
              </div>
              <div className="w-full ml-6 mt-1">
                <Typography variant="footnote">Hidden tasks are hidden from students and don't require a submission.</Typography>
                <Typography variant="footnote">If assessed by faculty, the student can then view the task in the tasks list under the "Closed" tab.</Typography>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="flex flex-col">
                <h5 className="font-bold">Units</h5>
                <p className="text-sm">All units added to the Experience settings must be covered in the Final project.</p>
                <div className="flex gap-2 flex-wrap mt-3">
                  {finalReviewUnits?.map((unit) => (
                    <Pill key={unit.value}>
                      <Pill.Value>{unit.label}</Pill.Value>
                    </Pill>
                  ))}
                </div>
              </div>
              <div className="flex flex-col">
                <h5 className="font-bold">Abilities</h5>
                <p className="text-sm">All ablities added to the Experience settings must be covered in the Final project.</p>
                <div className="flex flex-wrap gap-2 mt-3">
                  {finalReviewAbilities?.map((ability) => (
                    <Pill key={ability.value}>
                      <Pill.Value>{ability.label}</Pill.Value>
                    </Pill>
                  ))}
                </div>
              </div>
            </div>
          </BlockContent>
        </BlockWrapper>
      )}
      {!isFinalDeliverableVisible && (
        <div className="flex px-8 py-4 border-2 border-dashed border-gray-40 rounded-lg justify-center items-center">
          <Button onClick={() => setIsFinalDeliverableVisible(true)} variant="ghost" type="button">Add Final Deliverable</Button>
        </div>
      )}
    </BlockWrapper>
  )
}

interface FilterPillProps {
  values: LabelValue[],
  removeable?: boolean,
  onRemove: (id: string) => void,
}

function FilterPills({ values, removeable = true, onRemove }: FilterPillProps) {
  return (
    <div className="flex flex-wrap gap-2 mt-3">
      {values.map((value) => (
        <Pill key={value.value}>
          <Pill.Value>{value.label}</Pill.Value>
          {removeable && <button onClick={() => onRemove(value.value)}><Pill.Icon name="cross" /></button>}
        </Pill>
      ))}
    </div>
  )
}

interface ProjectsBlockProps {
  units: LabelValueBySchoolStage,
  abilities: LabelValueBySchoolStage,
  defaultData: Project[],
  setFormDirty: () => void,
  errors: Pick<ActionData['errors'], 'projects'>
}

function ProjectsBlock({ units, abilities, defaultData, errors, setFormDirty }: ProjectsBlockProps) {
  const [formData] = useFormContext()
  const [projects, setProjects] = React.useState<Project[]>(defaultData ?? [])
  const currentUnits = useSchoolStageBasedValue(units)

  const templateUnitIds = formData?.units?.split(',') ?? []
  const projectAvailableUnits = currentUnits?.filter((unit) => !templateUnitIds?.includes(unit.value))
  const notSelectedUnits = projectAvailableUnits

  const handleAddUnit = (projectId: string) => (unitId: string) => {
    setProjects((prevState) => {
      return [...prevState.map((project) => {
        if (project.id === projectId) {
          const units = project.units
          units.push(unitId)
          return {
            ...project,
            units: units
          }
        }
        return project
      })]
    })
  }

  const handleRemoveUnit = (projectId: string) => (unitId: string) => {
    setProjects((prevState) => {
      return [...prevState.map((project) => {
        if (project.id === projectId) {
          const units = project.units.filter((pUnit) => pUnit !== unitId)
          return {
            ...project,
            units: units
          }
        }
        return project
      })]
    })
  }

  const currentAbilities = useSchoolStageBasedValue(abilities)
  const templateAbilityIds = formData?.abilities?.split(',') ?? []
  const projectAvailableAbilities = currentAbilities.filter((ability) => !templateAbilityIds?.includes(ability.value))
  const notSelectedAbilities = projectAvailableAbilities

  const handleAddAbility = (projectId: string) => (abilityId: string) => {
    setProjects((prevState) => {
      return [...prevState.map((project) => {
        if (project.id === projectId) {
          const abilities = project.abilities
          abilities.push(abilityId)
          return {
            ...project,
            abilities: abilities
          }
        }
        return project
      })]
    })
  }

  const handleRemoveAbility = (projectId: string) => (abilityId: string) => {
    setProjects((prevState) => {
      return [...prevState.map((project) => {
        if (project.id === projectId) {
          const abilities = project.abilities.filter((pUnit) => pUnit !== abilityId)
          return {
            ...project,
            abilities: abilities
          }
        }
        return project
      })]
    })
  }


  const handleAddProject = () => {
    setProjects((prevState) => {
      return prevState.length > 0
        ? [...prevState, { id: uniqueId(), title: '', description: '', units: [], abilities: [] }]
        : [{ id: uniqueId(), title: '', description: '', units: [], abilities: [] }]
    })
  }

  const handleRemoveProject = (id: string) => {
    setProjects((prevState) => {
      return [...prevState.filter((project) => project.id !== id)]
    })
  }

  return (
    <BlockWrapper id="projects">
      <BlockTitle>Projects</BlockTitle>
      <BlockContent className="gap-10">
        {projects.map((project, index) => {
          return (
            <div className="flex flex-col gap-8" key={project.id}>
              <div className="flex w-full justify-between items-center">
                <BlockTitle>Project {index + 1}</BlockTitle>
                <Button variant="ghost" color="danger" size="sm" type="button" onClick={() => handleRemoveProject(project.id)}>
                  <Icon name="trash" size="xs"></Icon>
                  <span>Remove project</span>
                </Button>
              </div>
              <BlockContent>
                <FormField className="w-full gap-1 flex flex-col" name={`projects[${index}][title]`}>
                  <SoraFormLabel>Project title</SoraFormLabel>
                  <FormControl asChild>
                    <TextField defaultValue={project.title} error={!!errors?.projects?.[index]?.title} />
                  </FormControl>
                </FormField>
                {errors?.projects?.[index]?.title && <SoraFormMessage>Project title is required</SoraFormMessage>}
                <FormField className="w-full gap-1 flex flex-col" name={`projects[${index}][description]`}>
                  <SoraFormLabel>Project description</SoraFormLabel>
                  <SlateTextarea
                    /* @ts-ignore: Unreachable code error */
                    onChange={setFormDirty}
                    noAttachments
                    value={project?.description}
                    id={`project${project.id}_description`}
                    name={`projects[${index}][description]`}
                    aria-label={`project${project.id}_description`}
                    placeholder="Type project description here"
                    className="h-48"
                  />
                </FormField>
                <div className="grid grid-cols-2 gap-6">
                  <FormField className="w-full gap-1 flex flex-col" name={`projects[${index}][units]`}>
                    <SoraFormLabel>Project units</SoraFormLabel>
                    <input type="hidden" name={`projects[${index}][units]`} value={project.units.toString()} />
                    <Combobox options={notSelectedUnits} placeholder="Type to search for units" onChange={handleAddUnit(project.id)}></Combobox>
                    <FormDescription>All the core units will also be covered in a project.</FormDescription>
                    <FilterPills values={currentUnits.filter((unit) => project.units.includes(unit.value))} onRemove={handleRemoveUnit(project.id)} />
                  </FormField>
                  <FormField className="w-full gap-1 flex flex-col" name={`projects[${index}][abilities]`}>
                    <SoraFormLabel>Project abilities</SoraFormLabel>
                    <input type="hidden" name={`projects[${index}][abilities]`} value={project.abilities.toString()} />
                    <Combobox options={notSelectedAbilities} placeholder="Type to search for abilities" onChange={handleAddAbility(project.id)}></Combobox>
                    <FormDescription>All the core abilities will also be covered in a project.</FormDescription>
                    <FilterPills values={currentAbilities.filter((ability) => project.abilities.includes(ability.value))} onRemove={handleRemoveAbility(project.id)} />
                  </FormField>
                </div>
              </BlockContent>
            </div>
          )
        })}
        <span className="self-center">
          <Button type="button" onClick={handleAddProject}>Add Project</Button>
        </span>
      </BlockContent>
    </BlockWrapper>
  )
}

interface SessionsBlockProps {
  defaultData?: ExperienceSession[],
  setFormDirty: () => void,
  errors: Pick<ActionData['errors'], 'sessions'>
}

function SessionsBlock({ defaultData, errors, setFormDirty }: SessionsBlockProps) {
  const [formData, setFormData] = useFormContext()
  const [sessions, setSessions] = React.useState<ExperienceSession[]>(defaultData ?? [])

  React.useEffect(() => {
    const experienceTitleInput = document.querySelector('input[name="title"]') as HTMLInputElement
    const handleTitleChange = (e) => {
      setSessions((prevState) => {
        if (prevState.length === 0) return [...prevState]
        return [...prevState.map((session) => {
          const sessionIndex = session.title.split(' ')[1]
          return {
            ...session,
            title: `Session ${sessionIndex} - ${e.target.value}`,
          }
        })]
      })
    }
    experienceTitleInput.addEventListener('input', handleTitleChange)
    return () => {
      experienceTitleInput.removeEventListener('input', handleTitleChange)
    }
  }, [])

  const handleAddSession = () => {
    const templateTitle = (document.querySelector('input[name="title"]') as HTMLInputElement)?.value
    setSessions((prevState) => {
      const [defaultMinutes, defaultHours] = formData.type === 'extracurricular' ? [30, 17] : [10, 0]
      const newItem: ExperienceSession = {
        timestamptz: zonedTimeToUtc(setHours(setMinutes(new Date(), defaultMinutes), defaultHours), 'America/New_York').toISOString(),
        title: `Session ${prevState.length + 1} - ${templateTitle}`,
        rte_description: null,
        duration: formData.type === 'extracurricular' ? '01:30:00' : '00:50:00',
        conferenceUrl: '',
        localId: uniqueId('new_session_'),
      }

      setFormData((prevState) => {
        return {
          ...prevState,
          sessions: [...prevState?.sessions || [], newItem]
        }
      })
      return prevState.length > 0
        ? [...prevState, newItem]
        : [newItem]
    })
  }

  const handleDeleteSession = (id: string) => {
    setSessions((prevState) => {
      const state = [...prevState.filter((s) => (s.id ?? s.localId) !== id)]
      setFormData((prevState) => {
        return {
          ...prevState,
          sessions: [...prevState?.sessions?.filter((s) => (s.id ?? s.localId) !== id)]
        }
      })
      return state.map((session, index) => {
        return {
          ...session,
          title: `Session ${index + 1} - ${session.title.split(' - ')[1]}`,
        }
      })
    })
  }

  return (
    <BlockWrapper id="sessions" unstyled>
      {sessions.map((session, index) => {
        return (
          <BlockWrapper key={session.id || session.localId}>
            <div className="flex w-full justify-between items-center">
              <BlockTitle>{session.title}</BlockTitle>
              <Button variant="ghost" color="danger" size="sm" type="button" onClick={() => handleDeleteSession(session.id || session.localId)}>
                <Icon name="trash" size="xs"></Icon>
                <span>Remove session</span>
              </Button>
            </div>
            <BlockContent>
              <input type="hidden" name={`sessions[${index}][id]`} value={session?.id} />
              <input type="hidden" name={`sessions[${index}][local_id]`} value={session?.localId} />
              <FormField className="w-full gap-1 flex flex-col" name={`sessions[${index}][title]`}>
                <SoraFormLabel>Session title</SoraFormLabel>
                <FormControl asChild>
                  <TextField key={`${session.id}_${index}`} defaultValue={session.title} error={!!errors?.sessions?.[index]?.title} readOnly />
                </FormControl>
                {errors?.sessions?.[index]?.title && <SoraFormMessage>Session title is required</SoraFormMessage>}
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`sessions[${index}][rte_description]`}>
                <SoraFormLabel>Session description</SoraFormLabel>
                <SlateTextarea
                  /* @ts-ignore: the component is not written in TS and the compiler is yelling nonsense errors */
                  onChange={setFormDirty}
                  noAttachments
                  value={session?.rte_description}
                  id={`session${index}_description`}
                  name={`sessions[${index}][rte_description]`}
                  aria-label={`session${index}_description`}
                  placeholder="Type session description here"
                  className="h-48"
                />
                <FormDescription>Describe the intended objective of the session, guiding experts when planning how to run this experience.</FormDescription>
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`sessions[${index}][timestamptz]`}>
                <SoraFormLabel>Session Date ({getBrowserTimezoneAbbreviation()})</SoraFormLabel>
                <DateTimePicker
                  dateFormat="PPPPp"
                  defaultValue={session.timestamptz}
                  placeholderText="Select date and time"
                  name={`sessions[${index}][timestamptz]`}
                  error={!!errors?.sessions?.[index]?.timetstamptz}
                />
                {errors?.sessions?.[index]?.timetstamptz && <SoraFormMessage>Session date is required</SoraFormMessage>}
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`sessions[${index}][duration]`}>
                <SoraFormLabel>Session Duration</SoraFormLabel>
                <DurationInput defaultValue={session.duration} error={!!errors?.sessions?.[index]?.duration} name={`sessions[${index}][duration]`} />
                {errors?.sessions?.[index]?.duration && <SoraFormMessage>Session duration is required</SoraFormMessage>}
              </FormField>
            </BlockContent>
          </BlockWrapper>
        )
      })}
      <div className="flex px-8 py-4 border-2 border-dashed border-gray-40 rounded-lg justify-center items-center">
        <Button onClick={handleAddSession} variant="ghost" type="button">Add Session</Button>
      </div>
    </BlockWrapper>
  )
}

interface ExperienceExclusiveBlockProps {
  cycles: LabelValue[],
  errors: Pick<ActionData['errors'], 'max_students' | 'competition_interval_id' | 'enable_heartbeat'>,
  defaultData?: {
    heartbeat_channel_id?: string,
    heartbeat_group_id?: string,
    enable_heartbeat?: boolean,
    max_students: number,
    competition_interval_id: string,
  },
}

function ExperienceExclusiveBlock({ cycles, errors, defaultData }: ExperienceExclusiveBlockProps) {
  const hbFieldsData = {
    enable_heartbeat: defaultData?.enable_heartbeat,
    heartbeat_channel_id: defaultData?.heartbeat_channel_id,
    heartbeat_group_id: defaultData?.heartbeat_group_id,
  }

  return (
    <BlockWrapper id="experience_exclusive">
      <BlockTitle>Experience Exclusive</BlockTitle>
      <BlockContent>
        <HearbeatFields defaultData={hbFieldsData as HearbeatDefaultData} />
        <FormField className="w-full gap-1 flex flex-col" name="competition_interval_id">
          <SoraFormLabel>Cycle</SoraFormLabel>
          <FormControl asChild>
            <Select defaultValue={defaultData?.competition_interval_id}>
              <Select.Trigger>
                <Select.Value placeholder="Select cycle"></Select.Value>
              </Select.Trigger>
              <Select.Content>
                {cycles.map((cycle) => (
                  <Select.Item key={cycle.value} value={cycle.value}>
                    {cycle.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          </FormControl>
          {errors?.competition_interval_id && <SoraFormMessage>Cycle is required</SoraFormMessage>}
        </FormField>
        <FormField className="w-full gap-1 flex flex-col" name="max_students">
          <SoraFormLabel>Max students</SoraFormLabel>
          <FormControl asChild>
            <TextField type="number" min={0} defaultValue={defaultData?.max_students} error={!!errors?.max_students} />
          </FormControl>
          {errors?.max_students && <SoraFormMessage>Max student is required and must be greater than zero.</SoraFormMessage>}
        </FormField>
      </BlockContent>
    </BlockWrapper>
  )
}

type HearbeatDefaultData = {
  enable_heartbeat: boolean,
  heartbeat_channel_id: string,
  heartbeat_group_id: string,
}

type HearbeatFieldsProps = {
  defaultData?: HearbeatDefaultData,
}

function HearbeatFields({ defaultData }: HearbeatFieldsProps) {
  return (
    <>
      <FormField className="w-full gap-1 flex-col flex" name="enable_heartbeat">
        <div className="flex gap-2 items-center">
          <FormControl asChild>
            <input type="checkbox" defaultChecked={defaultData?.enable_heartbeat} />
          </FormControl>
          <SoraFormLabel>Enable Heartbeat</SoraFormLabel>
        </div>
        <FormDescription>This option will enable heartbeat integration for this expedition. We will automatically create a channel for it and add all the registered students and employees to it.</FormDescription>
      </FormField>
      <div className="flex items-center gap-6">
        <FormField className="w-full gap-1 flex flex-col" name="heartbeat_channel_id">
          <SoraFormLabel>Heartbeat channel id</SoraFormLabel>
          <FormControl asChild>
            <TextField readOnly placeholder="Created After Submit" defaultValue={defaultData?.heartbeat_channel_id} />
          </FormControl>
        </FormField>
        <FormField className="w-full gap-1 flex flex-col" name="heartbeat_group_id">
          <SoraFormLabel>Heartbeat channel group</SoraFormLabel>
          <FormControl asChild>
            <TextField readOnly placeholder="Created After Submit" defaultValue={defaultData?.heartbeat_group_id} />
          </FormControl>
        </FormField>
      </div>
    </>
  )
}

function SideNavigator({ isISE = true }) {
  const fullItems = [
    { id: 'template_settings', text: 'Experience Settings' },
    { id: 'units', text: 'Units' },
    { id: 'abilities', text: 'Abilities' },
    { id: 'instructor_notes', text: 'Instructor Notes' },
    { id: 'projects', text: 'Projects' },
    { id: 'experience_details', text: 'Experience Details' },
    { id: 'ise_details', text: 'ISE Details' },
    { id: 'essential_questions', text: 'Essential Questions' },
    { id: 'learning_objectives', text: 'Learning Objectives' },
    { id: 'materials_and_tools', text: 'Materials And Tools' },
    { id: 'learning_section', text: 'Six Week Plan' },
    { id: 'experience_exclusive', text: 'Experience Exclusive' },
    { id: 'guidebook', text: 'Expedition Guide' },
    { id: 'sessions', text: 'Sessions' },
    { id: 'final_project', text: 'Final Project' }
  ]

  const items = isISE ? fullItems : fullItems.filter(item => item.id !== 'ise_details')

  const scrollToElementWithId = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }

  return (
    <aside className="w-60">
      <div className="fixed top-[16.5rem] left-[calc(1rem+var(--aside-width))] bottom-0 h-[60svh] short:h-[55svh] overflow-y-auto">
        <ul className="space-y-4 h-full pb-16">
          {items.map((item) => (
            <li
              key={item.id}
              onClick={() => scrollToElementWithId(item.id)}
              className="cursor-pointer md:block hidden max-w-24 lg:max-w-full truncate md:w-full py-2 md:px-0 lg:px-4 self-start text-md rounded-lg font-semibold hover:font-bold hover:bg-gray-20"
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>
    </aside>
  )
}

type SchoolStage = 'ms' | 'hs'
type LabelValueBySchoolStage = Record<SchoolStage, LabelValue[]>

function useSchoolStageBasedValue(valueBySchoolStage: LabelValueBySchoolStage) {
  const [values, setValues] = React.useState(valueBySchoolStage?.hs ?? [])
  const [formData] = useFormContext()

  React.useEffect(() => {
    if (formData?.school_stage) {
      setValues(valueBySchoolStage[formData.school_stage])
    }
  }, [formData?.school_stage])

  return values
}

NEW_ExperienceFormRoute.loader = loader
NEW_ExperienceFormRoute.action = action
