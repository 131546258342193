import * as React from 'react'
import axios from 'axios'
import { Await, Outlet, useAsyncValue, useLoaderData, useParams } from 'react-router'
import EmptyStateIllustration from './illustration.svg'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import { Skeleton, Pill, Accordion, Typography, EmptyState, Button, Icon, unstable_Tooltip as Tooltip } from '@design-system'
import SoraLink from '@components/link'
import { format, setDay } from 'date-fns'

interface Experience {
  id: number,
  title: string,
  imageUrl: string,
  imageAlt: string,
  daysOfTheWeek: number[],
  time: string,
  tasksToBeAssessed?: number,
  showAttendance: boolean
}
interface LoaderData {
  myExperiences: Experience[]
  experiences: Experience[]
}

async function fetchData(url: string) {
  const res = await axios.get(url)
  return res.data
}

async function loader({ request, params }) {
  const url = new URL(request.url)
  const cycle_id = url.searchParams.get('cycle_id')
  const data = fetchData(`/backoffice/facilitate/${params.type}?cycle_id=${cycle_id}`)
  return { data }
}

function Element() {
  const loaderData = useLoaderData() as { data: LoaderData }
  const params = useParams()

  const types = {
    'expeditions-learning-goals': 'expeditions-learning-goals',
    'activities-and-ises': 'activities-and-ises',
    'dual-enrollments': 'dual-enrollments'
  }

  return (
    <React.Suspense fallback={<ExperiencesSkeleton />}>
      <Await resolve={loaderData.data}>
        {({ myExperiences, experiences }) => (
          <div className="space-y-6">
            {(myExperiences.length === 0 && experiences.length === 0) && (
              <FacilitateEmptyState variant={types[params.type] || 'expeditions-learning-goals'} />
            )}
            {myExperiences.length > 0 && <ExperiencesSection title="My experiences" experiences={myExperiences} />}
            {experiences.length > 0 && <ExperiencesSection title="All experiences" experiences={experiences} />}
          </div>
        )}
      </Await>
    </React.Suspense>
  )
}

function ExperiencesSection({ title, experiences }) {
  const params = useParams()
  const navigate = useNavigateWithCycle()

  const handleNavigate = (id: number) => {
    const destination = params?.experienceId && params?.experienceId === id.toString() ? '.' : id.toString()
    navigate(destination)
  }

  return (
    <div className="space-y-4">
      <Typography variant="heading-4" weight="bold">{title}</Typography>
      <Accordion type="single" collapsible defaultValue={params?.experienceId} className="gap-4 flex flex-col">
        {experiences.map((experience) => (
          <Accordion.Item key={experience.id} value={String(experience.id)}>
            <Accordion.Header className="items-center">
              <Accordion.Trigger onClick={() => handleNavigate(experience.id)}>
                <div className="flex justify-between items-center gap-4 grow">
                  <figure className="h-28 w-32 flex-shrink-0 hidden lg:flex">
                    <img className="w-32 h-28 rounded-sm aspect-square object-cover" src={experience.imageUrl} alt={experience.imageAlt} />
                  </figure>
                  <div className="flex flex-col gap-1 grow">
                    <Typography variant="heading-6" weight="bold" className="text-start line-clamp-1 overflow-hidden text-ellipsis">{experience.title}</Typography>
                    <Typography variant="body">
                      {experience.daysOfTheWeek && experience.time && `${experience.daysOfTheWeek.map((day) => format(setDay(new Date(), day), 'E')).join(' / ')} - ${format(new Date(experience.time), 'h:mm a')}`}
                    </Typography>
                  </div>
                  {experience.tasksToBeAssessed ? (
                    <div className="flex flex-col gap-1 justify-self-end">
                      <Pill color="blue-40" className="w-full">
                        <Pill.Value>{experience.tasksToBeAssessed} tasks</Pill.Value>
                      </Pill>
                      <Typography variant="footnote" weight="bold" className="whitespace-nowrap">to be reviewed</Typography>
                    </div>
                  ) : null}
                </div>
              </Accordion.Trigger>
              <Tooltip content="Attendance">
                <Button variant="outlined" color="soft" size="sm" asChild className="lg:hidden">
                  <SoraLink to={`${experience.id}/attendance/default`}>
                    <Icon name="calendar" size="sm" />
                  </SoraLink>
                </Button>
              </Tooltip>
              <Button variant="outlined" color="soft" size="sm" asChild className="hidden lg:flex">
                <SoraLink to={`${experience.id}/attendance/default`}>
                  Attendance
                </SoraLink>
              </Button>
              <Accordion.Trigger onClick={() => handleNavigate(experience.id)} className="grow-0">
                <Accordion.Icon />
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content>
              <Outlet />
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  )

}

interface FacilitateEmptyStateProps {
  variant: 'expeditions-learning-goals' | 'activities-and-ises' | 'dual-enrollments'
}

function FacilitateEmptyState({ variant }: FacilitateEmptyStateProps) {
  const { title, subtitle } = {
    'expeditions-learning-goals': {
      title: 'No expeditions or learning goals',
      subtitle: 'You are not facilitating any expeditions or learning goals in the selected cycle'
    },
    'activities-and-ises': {
      title: 'No activities or ISEs',
      subtitle: 'You are not facilitating any activities or ISEs in the selected cycle'
    },
    'dual-enrollments': {
      title: 'No dual enrollments',
      subtitle: 'You are not facilitating any dual enrollments in the selected cycle'
    }
  }[variant]

  return (
    <EmptyState>
      <EmptyState.Illustration>
        <img src={EmptyStateIllustration} alt="Hand drawn illustration of a puppet pointing to a rotatory globe" />
      </EmptyState.Illustration>
      <EmptyState.Title>{title}</EmptyState.Title>
      <EmptyState.Description>{subtitle}</EmptyState.Description>
    </EmptyState>
  )
}

function ExperiencesSkeleton() {
  return (
    <div className="flex flex-col gap-4">
      {Array.from({ length: 7 }, (_, j) => (
        <Skeleton key={`skeleton-${j}`} className="h-44" />
      ))}
    </div>
  )
}

export const FacilitateTypeRoute = { Element, loader }
