import { z } from 'zod'

export type MaterialsAndTools = {
  id: string,
  title: string,
  description: string,
  type: string,
  link?: string
}

export type LearningSection = {
  id: string,
  title: string,
  description: string,
}

export type Session = {
  id: string,
  title: string,
  rte_description: any[],
  sequenceNumber?: number,
  templateId?: number,
  tasks?: Task[]
}

type Task = {
  id: string,
  title: string,
  rte_description: any[],
  type: 'regular' | 'is_readonly' | 'use_guidebook' | 'hidden',
}

export type Project = {
  id: string,
  title: string,
  description: string,
  units?: string[],
  abilities?: string[]
}

export type LabelValue = {
  value: string,
  label: string
}

export const buildFormSchema = ({ enforceRequiredFields, isExtracurricular }) => {

  const isFinalDeliverableRequired = enforceRequiredFields && !isExtracurricular

  return z.object({
    type: z.string().min(1, { message: 'Type is required' }),
    is_draft: z.string().optional(),
    display_on_website: z.string().optional(),
    is_redesigned_template: enforceRequiredFields ? z.string() : z.string().optional(),
    short_description: z.string().max(500, { message: 'Short description must be less than 500 characters' }),
    category: z.string().optional(),
    school_stage: enforceRequiredFields ? z.string().min(1, { message: 'School stage is required' }) : z.string().optional(),
    experts: z.string().optional(),
    interest_tags: z.string().optional(),
    units: z.string().optional(),
    abilities: z.string().optional(),
    projects: z.array(
      z.object({
        title: z.string().optional(),
        description: z.string().optional(),
        units: z.string().optional(),
        abilities: z.string().optional(),
      })
    ).optional(),
    title: z.string().min(1, { message: 'Title is required' }),
    short_title: z.string().min(1, { message: 'Short title is required' }).max(80, { message: 'Short title must be less than 80 characters' }),
    rte_description: enforceRequiredFields ? z.string().min(1, { message: 'Description is required' }) : z.string().optional(),
    image_url: z.string(),
    image_file: z.any(),
    image_alt: z.string().optional(),
    video_section_video_url: z.string().url().optional().or(z.literal('')),
    instructor_notes: z.string().optional(),
    key_features_essential_questions_description: z.string().optional(),
    key_features_learning_objectives_description: z.string().optional(),
    materials: z.array(
      z.object({
        type: enforceRequiredFields ? z.string().min(1) : z.string().optional(),
        title: enforceRequiredFields ? z.string().min(1) : z.string().optional(),
        description: z.string().optional(),
        link: z.string().url().optional().or(z.literal('')),
      })
    ).optional(),
    sections: z.array(
      z.object({
        title: enforceRequiredFields ? z.string().min(1, { message: 'Title is required' }) : z.string().optional(),
        description: z.string().optional()
      })
    ).optional(),
    sessions: z.array(
      z.object({
        title: enforceRequiredFields ? z.string() : z.string().optional(),
        sequence_number: z.number().optional(),
        rte_description: z.string().optional(),
        tasks: z.array(
          z.object({
            type: enforceRequiredFields ? z.union([z.literal('regular'), z.literal('is_readonly'), z.literal('use_guidebook'), z.literal('is_hidden')]) : z.string().optional(),
            title: enforceRequiredFields ? z.string() : z.string().optional(),
            sequence_number: z.number().optional(),
            rte_description: z.string().optional(),
          }).optional(),
        ).optional()
      })
    ).optional(),
    final_deliverable: z.object({
      title: isFinalDeliverableRequired ? z.string().min(1, { message: 'Title is required' }) : z.string().optional(),
      rte_description: isFinalDeliverableRequired ? z.string().min(1, { message: 'Description is required' }) : z.string().optional(),
      type: enforceRequiredFields ? z.union([z.literal('regular'), z.literal('is_readonly'), z.literal('use_guidebook'), z.literal('is_hidden')]) : z.string().optional(),
    }).optional(),
  })
}
