import { useEffect, useRef } from 'react'
import { useBlocker } from 'react-router'
import useConfirmModal from './useConfirmModal'

/**
 * @description Hook to prevent the user from leaving the page if there are unsaved changes.
 */
export function useConfirmBeforeLeave({ blockingConditional }: { blockingConditional?: Parameters<typeof useBlocker>[0] } = {}) {
  const isFormDirty = useRef(false)
  const confirm = useConfirmModal()
  const blocker = useBlocker(blockingConditional ?? (({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname && isFormDirty.current))

  const setFormDirty = (value = true) => {
    isFormDirty.current = value
  }

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm({
        title: 'Are you sure you want to leave this page?',
        subtitle: 'You have unsaved changes. If you leave, your changes will be lost.',
        confirmLabel: 'Leave',
        cancelLabel: 'Cancel',
      }).then((confirm) => {
        if (confirm) {
          blocker.proceed()
        } else {
          blocker.reset()
        }
      })
    }
  }, [blocker.state])

  return [isFormDirty, setFormDirty] as const
}
