import React, { ComponentProps, ReactNode } from 'react'
import { Pill } from '@design-system'

export type PillStudentStatusVariant = 'active' | 'hold' | 'withdrawn' | 'leave_of_absence' | 'graduated' | 'admitted'

interface PillAllowedProps extends Pick<ComponentProps<typeof Pill>, 'rounded' | 'variant' | 'color' | 'disabled'> { }

interface VariantProps extends PillAllowedProps {
  childrenFn: (props: Omit<PillStudentStatusProps, 'variant'>) => ReactNode
}

const defaultProps: VariantProps = {
  childrenFn: ({ variant }: PillStudentStatusProps) => <Pill.Value>{variant}</Pill.Value>,
}

const variants: Record<PillStudentStatusVariant, VariantProps> = {
  active: {
    childrenFn: () => <Pill.Value>Active</Pill.Value>,
    color: 'success-30',
  },
  hold: {
    childrenFn: () => <Pill.Value>On Hold</Pill.Value>,
    color: 'notice-5',
  },
  withdrawn: {
    childrenFn: () => <Pill.Value>Withdrawn</Pill.Value>,
    color: 'danger-10',
  },
  leave_of_absence: {
    childrenFn: () => <Pill.Value>Leave of Absence</Pill.Value>,
    color: 'notice-5',
  },
  graduated: {
    childrenFn: () => <Pill.Value>Graduated</Pill.Value>,
    color: 'gray-10',
  },
  admitted: {
    childrenFn: () => <Pill.Value>Admitted</Pill.Value>,
    color: 'cyan-5',
  }
}

interface PillStudentStatusProps {
  variant: PillStudentStatusVariant
}

/**
 * PillTask display a pill with task status
 */
export const PillStudentStatus = (props: PillStudentStatusProps) => {
  const { childrenFn, ...pillProps } = variants[props.variant] ?? defaultProps
  return (
    <Pill {...pillProps}>
      {childrenFn({ ...props })}
    </Pill>
  )
}
