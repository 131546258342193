import axios from 'axios'
import React from 'react'
import { Outlet, redirect, useLoaderData, useLocation, useParams } from 'react-router'
import { LoaderResponse } from './types'
import { Button, Icon, Typography } from '@design-system'
import StudentTile from './student-tile'
import { SoraNavLink } from '@components/link'

async function loader({ params }): Promise<LoaderResponse> {
  const result = await axios.get(`/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}`)
  if (result.data.defaultStudentId && !params.studentId) {
    throw redirect(result.data.defaultStudentId ? `./students/${result.data.defaultStudentId}` : `/facilitate/expeditions-learning-goals`)
  }
  return result.data
}

function Element() {
  const loaderData = useLoaderData() as LoaderResponse
  const params = useParams()
  const location = useLocation()
  const shouldRedirectToStudentFeedback = location.pathname.endsWith('/feedback') && loaderData.isFinal

  return (
    <>
      <div className="flex h-full flex-row fixed top-0 right-0 bottom-0 left-0 z-50 bg-white overflow-x-auto">
        <div className="flex flex-col flex-1  min-w-72 max-w-72 overflow-hidden">
          <header className="p-4">
            <div className="pb-4">
              <Button asChild size="xs" variant="outlined" color="soft">
                <SoraNavLink to={`/facilitate/expeditions-learning-goals/${params?.experienceId ?? ''}`}>
                  <Icon name="chevron-left" size="xs" />
                </SoraNavLink>
              </Button>
            </div>

            <Typography variant="heading-6" weight="bold">
              {loaderData.taskTitle}
            </Typography>

            <Typography variant="callout" weight="regular">
              {loaderData.themeTitle}
            </Typography>
          </header>

          <div className="p-6 border-y border-neutral-200 items-center">
            <div className="gap-2 flex">
              <Icon name="calendar" className="w-6 h-6" />

              <div className="grow basis-0 text-neutral-900 text-base font-normal font-['PP Agrandir Text'] leading-normal">
                {new Intl.DateTimeFormat('en-US', {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })
                  .format(new Date(loaderData.dueAt))
                  .replace(/, \d{4}$/, ' - ') + new Date(loaderData.dueAt).getFullYear()}
              </div>
            </div>
          </div>

          <ul className="flex flex-1 flex-col gap-4 overflow-auto p-6">
            {loaderData.statusGroups.map((status) => (
              <li key={status.name} className="flex flex-col gap-4">
                <Typography variant="body" weight="bold">
                  {status.name} ({status.students.length})
                </Typography>

                <ul className="flex flex-col gap-2">
                  {status.students.map((student) => (
                    <StudentTile key={student.id} student={student} to={`students/${student.id}${shouldRedirectToStudentFeedback ? '/feedback' : ''}`} />
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        <Outlet />
      </div>
    </>
  )
}

export const FacilitateExperienceTaskRoute = {
  loader,
  Element,
}
