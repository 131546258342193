import React, { PropsWithChildren, ReactNode } from 'react'
import { Card, Typography, Icon, cn } from '@design-system'

export type CardGraduationProgressVariant = 'completed' | 'ahead-of-schedule' | 'on-track' | 'off-track' | 'falling-behind'

interface VariantProps {
  childrenFn: (title: String) => ReactNode
  bgColor?: string
}

const defaultProps: VariantProps = {
  childrenFn: (title) => (
    <>
      <div className="flex gap-2 items-center">
        <Typography variant="heading-5" weight="bold" className="dark:text-gray-100">-</Typography>
      </div>
      <Typography variant="subheadline" className="dark:text-gray-100">{title}</Typography>
    </>
  ),
}

const variants: Record<CardGraduationProgressVariant, VariantProps> = {
  completed: {
    childrenFn: (title) => (
      <>
        <div className="flex gap-2 items-center">
          <Typography variant="heading-5" weight="bold" className="dark:text-gray-100">Completed</Typography>
          <Icon name="check-circle" size="sm" className="dark:text-gray-100" />
        </div>
        <Typography variant="subheadline" className="dark:text-gray-100">{title}</Typography>
      </>
    ),
    bgColor: 'bg-green-20 dark:bg-green-20'
  },
  'ahead-of-schedule': {
    childrenFn: (title) => (
      <>
        <div className="flex gap-2 items-center">
          <Typography variant="heading-5" weight="bold" className="dark:text-gray-100">Ahead of schedule</Typography>
          <Icon name="star" size="sm" className="dark:text-gray-100" />
        </div>
        <Typography variant="subheadline" className="dark:text-gray-100">{title}</Typography>
      </>
    ),
    bgColor: 'bg-success-40 dark:bg-success-40'
  },
  'on-track': {
    childrenFn: (title) => (
      <>
        <div className="flex gap-2 items-center">
          <Typography variant="heading-5" weight="bold" className="dark:text-gray-100">On track</Typography>
          <Icon name="thumbs-up" size="sm" className="dark:text-gray-100" />
        </div>
        <Typography variant="subheadline" className="dark:text-gray-100">{title}</Typography>
      </>
    ),
    bgColor: 'bg-notice-25 dark:bg-notice-25'
  },
  'off-track': {
    childrenFn: (title) => (
      <>
        <div className="flex gap-2 items-center">
          <Typography variant="heading-5" weight="bold" className="dark:text-gray-100">Off track</Typography>
          <Icon name="alert-octagon" size="sm" className="dark:text-gray-100" />
        </div>
        <Typography variant="subheadline" className="dark:text-gray-100">{title}</Typography>
      </>
    ),
    bgColor: 'bg-orange-10 dark:bg-orange-10'
  },
  'falling-behind': {
    childrenFn: (title) => (
      <>
        <div className="flex gap-2 items-center">
          <Typography variant="heading-5" weight="bold" className="dark:text-gray-100">Falling behind</Typography>
          <Icon name="alert-triangle" size="sm" className="dark:text-gray-100" />
        </div>
        <Typography variant="subheadline" className="dark:text-gray-100">{title}</Typography>
      </>
    ),
    bgColor: 'bg-warning-20 dark:bg-warning-20'
  },
}

interface CardGraduationProgressProps extends PropsWithChildren {
  variant?: CardGraduationProgressVariant
  title: string
}

/**
 * PillGraduationProgress display a pill with student progress
 */
export const CardGraduationProgress = ({ variant, children, title }: CardGraduationProgressProps) => {
  const { childrenFn, bgColor } = variants[variant] ?? defaultProps
  return (
    <Card className={cn(bgColor, "group/card flex justify-between items-center grow")} data-testid="card-graduation-progress">
      <Card.Content className="space-y-1">
        {childrenFn(title)}
      </Card.Content>
      {children}
    </Card>
  )
}
