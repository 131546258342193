import React from 'react'
import format from 'date-fns/format'
import { Student } from './types'
import SoraLink from '@components/link'
import { Typography, Icon, Button, unstable_Tooltip as Tooltip } from '@design-system'

interface CellGraduationProps extends Pick<Student, 'id' | 'graduation' | 'status' | 'schoolStage'> { }

export const CellGraduation = ({ id, graduation, status, schoolStage }: CellGraduationProps) => {
  if (!graduation) return null
  const prefix = status !== 'graduated' ? 'Expected: ' : null
  const graduationDate = format(new Date(`${graduation}`), 'MMM d, yyyy')

  return (
    <div className="flex items-center gap-2 justify-between group/graduation">
      <Typography variant="callout">
        {prefix && <i>{prefix}</i>}
        {graduationDate}
      </Typography>
      {status == 'active' && schoolStage === 'hs' && (
        <Tooltip content="Mark student as graduated">
          <Button variant="ghost" size="xs" asChild color="soft" className="opacity-0 group-hover/graduation:opacity-100">
            <SoraLink to={`/students/graduation/${id}`}>
              <Icon name="check" size="xs" />
            </SoraLink>
          </Button>
        </Tooltip>
      )}
    </div>
  )
}
