import * as React from 'react'
import axios from 'axios'
import uniqueId from 'lodash/uniqueId'
import { captureException } from '@sentry/react'
import { Form, FormControl, FormField } from '@radix-ui/react-form'
import { Link, Form as RemixForm, useLoaderData, useActionData, useNavigate, useNavigation, LoaderFunctionArgs, useParams } from 'react-router'
import { useToast } from '@hooks/useToast'
import { TextField } from '@designsystem'
import { handleZodValidation } from './validation'
import useConfirmModal from '@hooks/useConfirmModal'
import serializeFormData from '@utils/serializeFormData'
import SlateTextarea from '@components/forms/slate-textarea'
import { TaskTypeRadioGroup } from './task-type-radio-group'
import { FormProvider, useFormContext } from './form-context'
import { Combobox, useFormControlledCombobox } from './combobox'
import { useConfirmBeforeLeave } from '@hooks/useConfirmBeforeLeave'
import { BlockContent, BlockTitle, BlockWrapper, FormDescription, SoraFormLabel, SoraFormMessage } from './primitives'
import { type LabelValue, type LearningSection, type MaterialsAndTools, type Project, type Session, buildFormSchema } from './types'
import { unstable_Tooltip as Tooltip, Button, Icon, Pill, unstable_Select as Select, unstable_RadioGroup as RadioGroup, cn, Typography, FileUploader, Dialog, Spinner } from '@design-system'
import useSWR from 'swr'

type LoaderData = Awaited<ReturnType<typeof loader>>
type ActionData = Awaited<ReturnType<typeof action>>

type Guidebook = {
  id: string,
  name: string,
  url: string,
}

type GuidebookFolder = {
  name?: string,
  childrenFiles: Guidebook[],
  childrenFolders: GuidebookFolder[],
  parentFolder?: GuidebookFolder,
}

async function loader({ params }) {
  const templateId = params.templateId
  const { data: {
    canUserEditAllFields,
    guidebooksGoogleDriveFolder,
    template: {
      units,
      abilities,
      employees,
      cycleThemes,
      categories,
      schoolStage,
      interestTags,
      experienceTypes,
      taskTypes,
      materialTypes,
      defaultData,
    },
  }
  } = await axios.get(`/backoffice/template/${templateId ? `${templateId}/edit` : 'edit'}`)
  return {
    units,
    abilities,
    employees,
    cycleThemes,
    categories,
    schoolStage,
    interestTags,
    taskTypes,
    experienceTypes,
    materialTypes,
    defaultData,
    canUserEditAllFields,
    guidebooksGoogleDriveFolder,
  }
}

async function action({ request, params }: LoaderFunctionArgs) {
  const templateId = params.templateId
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const arrayKeys = ['sessions', 'materials', 'sections', 'projects']

  arrayKeys.forEach((key) => {
    if (serializedFormData[key]) {
      let newValue = Array.isArray(serializedFormData[key]) ? serializedFormData[key] : Object.values(serializedFormData[key])
      if (key === 'sessions') {
        newValue = newValue.map((session) => {
          return {
            ...session,
            tasks: Array.isArray(session.tasks) ? session.tasks : Object.values(session.tasks || {})
          }
        })
      }
      serializedFormData[key] = newValue
    }
  })

  const _action = formData.get('_action')
  const toastMessageVerb = _action === 'edit' ? { past: 'updated', present: 'updating' } : { past: 'created', present: 'creating' }

  const validationResult = handleZodValidation({
    data: serializedFormData, schema: buildFormSchema({
      enforceRequiredFields: !serializedFormData.is_draft,
      isExtracurricular: serializedFormData.type === 'extracurricular'
    })
  })
  if (validationResult.type === 'error') {
    return {
      errors: validationResult.error,
      toast: {
        message: `An error ocurred during the validation of the fields. Please check the form and try again.`,
        type: 'error',
      }
    }
  }
  try {
    const { data } = await axios.post(`/backoffice/template/${templateId ? `${templateId}/edit` : 'edit'}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const templatePreviewUrl = data.templatePreviewUrl

    return {
      redirectTo: '../../template/library',
      toast: {
        message: `Template ${toastMessageVerb.past} successfully`,
        type: 'success',
        action: { label: <Button size="xs">Preview changes <Icon size="xs" name="external-link" /></Button>, onClick: () => window.open(templatePreviewUrl, '_blank') }
      }
    }
  } catch (error) {
    captureException(error)
    return {
      toast: {
        message: `An error occurred while ${toastMessageVerb.present} the template`,
        type: 'error',
      }
    }
  }
}

export function NEW_TemplateFormRoute() {
  const { defaultData } = useLoaderData() as LoaderData
  const pageTitle = defaultData?.template?.title ? `Edit "${defaultData?.template?.title}"` : 'New Template'

  return (
    <React.Fragment>
      <div className="bg-screen-primary right-0 top-20 py-2 left-64 z-10 fixed max-w-[90vw]">
        <Typography variant="heading-4" weight="bold" className="text-ellipsis line-clamp-1">{pageTitle}</Typography>
      </div>
      <div className="flex pt-12 pb-16 gap-8">
        <FormProvider>
          <SideNavigator />
          <TemplateForm />
        </FormProvider>
      </div>
    </React.Fragment>
  )
}

function TemplateForm() {
  const {
    units,
    abilities,
    employees,
    cycleThemes,
    categories,
    schoolStage,
    taskTypes,
    experienceTypes,
    materialTypes,
    defaultData,
    interestTags,
    canUserEditAllFields,
    guidebooksGoogleDriveFolder,
  } = useLoaderData() as LoaderData
  const actionData = useActionData() as ActionData
  const navigate = useNavigate()
  const confirm = useConfirmModal()
  const params = useParams()
  const [_, setFormData] = useFormContext()
  const [isFormDirty, setFormDirty] = useConfirmBeforeLeave({ blockingConditional: ({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname && !actionData?.redirectTo && isFormDirty.current })
  const errors = actionData?.errors

  React.useEffect(() => {
    const formElement = document.getElementById('template-form')
    if (formElement) {
      formElement.addEventListener('change', () => setFormDirty(true))
    }

    return () => formElement?.removeEventListener('change', () => setFormDirty(true))
  }, [])

  useToast(actionData)

  React.useEffect(() => {
    const firstErrorElement = document.getElementsByClassName('error-form-message')[0]
    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [actionData?.errors])

  React.useEffect(() => {
    if (actionData?.redirectTo) {
      navigate(actionData.redirectTo)
    }
  }, [actionData?.redirectTo])

  React.useEffect(() => {
    setFormData(() => {
      return {
        is_draft: String(defaultData?.isDraft ?? false),
        sessions: defaultData?.sessions,
        type: defaultData?.template?.type,
        school_stage: defaultData?.template?.schoolStage,
        units: defaultData?.units?.map((item) => item.id)?.toString(),
        abilities: defaultData?.abilities?.map((item) => item.id)?.toString(),
        employees: defaultData?.employees?.map((item) => item.employee_id)?.toString(),
        interest_tags: defaultData?.interestTags?.map((item) => item.id)?.toString(),
        cycle_themes: defaultData?.cycleThemes?.map((item) => item.cycle_theme_id)?.toString(),
      }
    })
  }, [])

  // ! always free to adit fields in the form: sessions, tools and materials and learning sections
  return (
    <Form asChild>
      <RemixForm id="template-form" className="flex flex-col gap-6 w-full mb-12" method="POST" noValidate encType="multipart/form-data">
        {/* TODO: This input should be deleted once we fully migrate to this new template structure */}
        <input type="hidden" name="is_redesigned_template" value="true" />
        <input type="hidden" name="_action" value={params.templateId ? 'edit' : 'create'} />
        <SettingsBlock canUserEditAllFields={canUserEditAllFields} defaultData={defaultData.template} experts={employees} cycleThemes={cycleThemes} schoolStage={schoolStage} experienceTypes={experienceTypes} categories={categories} errors={errors} setFormDirty={setFormDirty} />
        <InterestTagsBlock canUserEditAllFields={canUserEditAllFields} interests={interestTags} />
        <UnitsBlock canUserEditAllFields={canUserEditAllFields} units={units} />
        <AbilitiesBlock canUserEditAllFields={canUserEditAllFields} abilities={abilities} />
        <InstructorNotesBlock canUserEditAllFields={canUserEditAllFields} defaultData={defaultData.template} setFormDirty={setFormDirty} />
        <ProjectsBlock canUserEditAllFields={canUserEditAllFields} defaultData={defaultData.projects} units={units} abilities={abilities} errors={errors} setFormDirty={setFormDirty} />
        <DetailsBlock canUserEditAllFields={canUserEditAllFields} defaultData={defaultData.template} errors={errors} setFormDirty={setFormDirty} />
        <EssentialQuestionsBlock canUserEditAllFields={canUserEditAllFields} defaultData={defaultData.template} errors={errors} setFormDirty={setFormDirty} />
        <LearningObjectivesBlock canUserEditAllFields={canUserEditAllFields} defaultData={defaultData.template} errors={errors} setFormDirty={setFormDirty} />
        <MaterialsAndToolsBlock defaultData={defaultData.materialsAndTools} materialTypes={materialTypes} errors={errors} setFormDirty={setFormDirty} />
        <LearningSectionsBlock defaultData={defaultData.sections} errors={errors} setFormDirty={setFormDirty} />
        <GuidebookBlock defaultData={defaultData.guidebook} guidebooksGoogleDriveFolder={guidebooksGoogleDriveFolder} />
        <SessionsBlock defaultData={defaultData.sessions} setFormDirty={setFormDirty} taskTypes={taskTypes} />
        <FinalDeliverableBlock canUserEditAllFields={canUserEditAllFields} defaultData={defaultData.finalDeliverable} units={units} abilities={abilities} errors={errors} setFormDirty={setFormDirty} taskTypes={taskTypes} />
        <FormFooter />
      </RemixForm>
    </Form>
  )

}

interface LearningObjectivesBlockProps {
  canUserEditAllFields: boolean,
  setFormDirty: () => void,
  defaultData?: { key_features_learning_objectives_description: any[] },
  errors?: Pick<ActionData['errors'], 'key_features_learning_objectives_description'>
}

function LearningObjectivesBlock({ defaultData, errors, canUserEditAllFields, setFormDirty }: LearningObjectivesBlockProps) {
  const learingObjectivesRef = React.useRef(null)
  return (
    <BlockWrapper id="learning_objectives">
      <BlockTitle>Learning Objectives</BlockTitle>
      <BlockContent>
        <FormField className="w-full gap-1 flex flex-col" name="key_features_learning_objectives_description">
          <SoraFormLabel>Learning objectives description</SoraFormLabel>
          <SlateTextarea
            /* @ts-ignore: Unreachable code error */
            id="key_features_learning_objectives_description"
            name="key_features_learning_objectives_description"
            onChange={setFormDirty}
            ref={learingObjectivesRef}
            readOnly={!canUserEditAllFields}
            aria-label="key_features_learning_objectives_description"
            noAttachments={true}
            value={defaultData?.key_features_learning_objectives_description}
            className="h-48" />
        </FormField>
        {errors?.key_features_learning_objectives_description && <SoraFormMessage>Learning objectives description is required</SoraFormMessage>}
      </BlockContent>
    </BlockWrapper>
  )
}

interface SettingsBlockProps {
  canUserEditAllFields: boolean,
  experts: LabelValue[],
  cycleThemes: LabelValue[],
  categories: LabelValue[],
  schoolStage: LabelValue[],
  experienceTypes: LabelValue[],
  setFormDirty: () => void,
  defaultData: {
    experts: string
    category: string
    schoolStage: string
    type: string
    isDraft: boolean
    displayOnWebsite: boolean
  },
  errors?: Pick<ActionData['errors'], 'category' | 'school_stage' | 'type' | 'experts'>
}

function SettingsBlock({ cycleThemes, experts, schoolStage, experienceTypes, categories, defaultData, errors, canUserEditAllFields, setFormDirty }: SettingsBlockProps) {
  const [formData, setFormData] = useFormContext()
  const { handleAddParam, handleRemoveParam, notSelectedItems, selectedItems, selectedItemString } = useFormControlledCombobox({ values: experts, formKey: 'employees' })
  const {
    handleAddParam: handleAddCycleThemeParam,
    handleRemoveParam: handleRemoveCycleThemeParam,
    notSelectedItems: notSelectedCycleThemeItems,
    selectedItems: selectedCycleThemeItems,
    selectedItemString: selectedCycleThemeItemString,
  } = useFormControlledCombobox({ values: cycleThemes, formKey: 'cycle_themes' })

  const handleTypeChange = (value: string) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        type: value
      }
    })
  }

  const handleSchoolStageChange = (value: string) => {
    setFormDirty()
    setFormData((prevState) => {
      if (value !== prevState?.school_stage) {
        return {
          ...prevState,
          units: '',
          abilities: '',
          school_stage: value
        }
      }
      return { ...prevState }
    })
  }

  return (
    <BlockWrapper id="template_settings">
      <BlockTitle>Template Settings</BlockTitle>
      <BlockContent>
        <div className="flex gap-6">
          <FormField className="gap-2 flex items-center" name="is_draft">
            <FormControl asChild>
              <input
                type="checkbox"
                disabled={!canUserEditAllFields}
                defaultChecked={defaultData?.isDraft ?? true}
                className={!canUserEditAllFields ? 'cursor-not-allowed' : ''}
              />
            </FormControl>
            <SoraFormLabel>Draft</SoraFormLabel>
          </FormField>
          <FormField className="gap-2 flex items-center" name="display_on_website">
            <FormControl asChild>
              <input
                type="checkbox"
                disabled={!canUserEditAllFields}
                defaultChecked={defaultData?.displayOnWebsite ?? false}
                className={!canUserEditAllFields ? 'cursor-not-allowed' : ''}
              />
            </FormControl>
            <SoraFormLabel>Display on Website</SoraFormLabel>
          </FormField>
        </div>
        <div className="gap-6 flex">
          <FormField className="w-full gap-1 flex flex-col" name="type">
            <SoraFormLabel>Type</SoraFormLabel>
            <FormControl asChild>
              <Select onValueChange={handleTypeChange} defaultValue={defaultData?.type} disabled={!canUserEditAllFields}>
                <Select.Trigger className={errors?.type ? 'border-danger-40' : ''}>
                  <Select.Value placeholder="Select type"></Select.Value>
                </Select.Trigger>
                <Select.Content>
                  {experienceTypes.map((type) => (
                    <Select.Item key={type.value} value={type.value}>
                      {type.label}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            </FormControl>
            {errors?.type && <SoraFormMessage>Type is required</SoraFormMessage>}
          </FormField>
          {!canUserEditAllFields && <input type="hidden" name="type" value={defaultData?.type} />}
          {formData?.type === 'expedition' ? (
            <FormField className="w-full gap-1 flex flex-col" name="category" >
              <SoraFormLabel>Category</SoraFormLabel>
              <FormControl asChild>
                <Select defaultValue={defaultData?.category} disabled={!canUserEditAllFields}>
                  <Select.Trigger className={errors?.category ? 'border-danger-40' : ''}>
                    <Select.Value placeholder="Select category"></Select.Value>
                  </Select.Trigger>
                  <Select.Content>
                    {categories.map((category) => (
                      <Select.Item key={category.value} value={category.value}>
                        {category.label}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              </FormControl>
              {errors?.category && <SoraFormMessage>Category is required</SoraFormMessage>}
            </FormField>
          ) : null}
        </div>
        <FormField className="w-full gap-1 flex flex-col" name="school_stage" >
          <SoraFormLabel>School Stage</SoraFormLabel>
          <FormControl asChild >
            <RadioGroup defaultValue={defaultData?.schoolStage ?? schoolStage[0].value} onValueChange={handleSchoolStageChange} disabled={!canUserEditAllFields}>
              {schoolStage.map((stage) => (
                <RadioGroup.Item key={stage.value} value={stage.value} label={stage.label} />
              ))}
            </RadioGroup>
          </FormControl>
          {errors?.school_stage && <SoraFormMessage>School stage is required</SoraFormMessage>}
        </FormField>
        {!canUserEditAllFields && <input type="hidden" name="school_stage" value={defaultData?.schoolStage ?? schoolStage[0].value} />}
        {formData?.school_stage === 'ms' && formData.type == 'expedition' && canUserEditAllFields ?
          <FormField className="w-full gap-1 flex flex-col" name="cycle_themes">
            <SoraFormLabel>Available cycles</SoraFormLabel>
            <div className="flex flex-col gap-2">
              <div>
                <input type="hidden" name="cycle_themes" value={selectedCycleThemeItemString} />
                <Combobox options={notSelectedCycleThemeItems} placeholder="Type to search for cycle name" onChange={handleAddCycleThemeParam}></Combobox>
                <FormDescription>Select all cycles in which this expedition should be available for students</FormDescription>
                <FilterPills values={selectedCycleThemeItems} onRemove={handleRemoveCycleThemeParam} removeable={canUserEditAllFields} />
              </div>
            </div>
          </FormField>
          : null}
        <FormField className="w-full gap-1 flex flex-col" name="experts">
          <SoraFormLabel>Experts</SoraFormLabel>
          <div className="flex flex-col gap-2">
            <div>
              <input type="hidden" name="experts" value={selectedItemString} />
              <Combobox options={notSelectedItems} placeholder="Type to search for expert name" onChange={handleAddParam} disabled={!canUserEditAllFields}></Combobox>
              <FormDescription>Add here all experts that can conduct this experience. This field is not visible to students.</FormDescription>
              <FilterPills values={selectedItems} onRemove={handleRemoveParam} removeable={canUserEditAllFields} />
            </div>
          </div>
        </FormField>
      </BlockContent>
    </BlockWrapper>
  )
}

interface InterestTagsBlockProps {
  canUserEditAllFields: boolean,
  interests: LabelValue[]
}

function InterestTagsBlock({ interests, canUserEditAllFields }: InterestTagsBlockProps) {
  const { handleAddParam, handleRemoveParam, notSelectedItems, selectedItems, selectedItemString } = useFormControlledCombobox({ values: interests, formKey: 'interest_tags' })

  return (
    <BlockWrapper id="interest_tags">
      <BlockTitle>Interest Tags</BlockTitle>
      <BlockContent>
        <div>
          <input type="hidden" name="interest_tags" value={selectedItemString} />
          <Combobox options={notSelectedItems} placeholder="Type to search for interest tags" onChange={handleAddParam} disabled={!canUserEditAllFields}></Combobox>
          <FormDescription>Add tags related to expedition subject. This will help students finding expeditions that match their interests.</FormDescription>
          <FilterPills values={selectedItems} onRemove={handleRemoveParam} removeable={canUserEditAllFields} />
        </div>
      </BlockContent>
    </BlockWrapper>
  )
}

interface UnitsBlockProps {
  canUserEditAllFields: boolean,
  units: LabelValueBySchoolStage,
}

function UnitsBlock({ units, canUserEditAllFields }: UnitsBlockProps) {
  const currentUnits = useSchoolStageBasedValue(units)
  const { handleAddParam, handleRemoveParam, notSelectedItems, selectedItemString, selectedItems } = useFormControlledCombobox({ values: currentUnits, formKey: 'units' })

  return (
    <BlockWrapper id="units">
      <BlockTitle>Core Units</BlockTitle>
      <BlockContent>
        <div>
          <input type="hidden" name="units" value={selectedItemString} />
          <Combobox options={notSelectedItems} placeholder="Type to search for units" onChange={handleAddParam} disabled={!canUserEditAllFields}></Combobox>
          <FormDescription>You can add multiple units.</FormDescription>
          <FilterPills values={selectedItems} onRemove={handleRemoveParam} removeable={canUserEditAllFields} />
        </div>
      </BlockContent>
    </BlockWrapper>
  )
}

interface AbilitiesBlockProps {
  canUserEditAllFields: boolean,
  abilities: LabelValueBySchoolStage,
}

function AbilitiesBlock({ abilities, canUserEditAllFields }: AbilitiesBlockProps) {
  const currentAbilities = useSchoolStageBasedValue(abilities)
  const { handleAddParam, handleRemoveParam, notSelectedItems, selectedItemString, selectedItems } = useFormControlledCombobox({ values: currentAbilities, formKey: 'abilities' })

  return (
    <BlockWrapper id="abilities">
      <BlockTitle>Core Abilities</BlockTitle>
      <BlockContent>
        <div>
          <input type="hidden" name="abilities" value={selectedItemString} />
          <Combobox options={notSelectedItems} placeholder="Type to search for ablilities" onChange={handleAddParam} disabled={!canUserEditAllFields}></Combobox>
          <FormDescription>You can add multiple abilities.</FormDescription>
          <FilterPills values={selectedItems} onRemove={handleRemoveParam} removeable={canUserEditAllFields} />
        </div>
      </BlockContent>
    </BlockWrapper>
  )
}

interface InstructorNotesBlockProps {
  canUserEditAllFields: boolean,
  setFormDirty: () => void,
  defaultData?: { instructor_notes: any[] },
}

function InstructorNotesBlock({ defaultData, canUserEditAllFields, setFormDirty }: InstructorNotesBlockProps) {
  const instructorNotesRef = React.useRef(null)
  return (
    <BlockWrapper id="instructor_notes">
      <BlockTitle>Instructor notes</BlockTitle>
      <BlockContent>
        <FormField className="w-full gap-1 flex flex-col" name="instructor_notes">
          <SoraFormLabel>Instructor notes</SoraFormLabel>
          <SlateTextarea
            /* @ts-ignore: Unreachable code error */
            id="instructor_notes"
            onChange={setFormDirty}
            name="instructor_notes"
            readOnly={!canUserEditAllFields}
            ref={instructorNotesRef}
            aria-label="instructor_notes"
            noAttachments={true}
            value={defaultData?.instructor_notes}
            className="h-48" />
          <FormDescription>Add notes or relevant links for the instructor. Those won't be visible to students.</FormDescription>
        </FormField>
      </BlockContent>
    </BlockWrapper>
  )
}

interface DetailsBlockProps {
  canUserEditAllFields: boolean,
  setFormDirty: () => void,
  defaultData?: {
    title: string,
    shortTitle: string,
    rte_description: any[],
    image_url: string,
    image_alt: string,
    shortDescription?: string,
    video_section_video_url?: string
  },
  errors?: Pick<ActionData['errors'], 'short_description' | 'title' | 'short_title' | 'image_alt' | 'image_url' | 'rte_description' | 'video_section_video_url'>
}

function DetailsBlock({ defaultData, errors, canUserEditAllFields, setFormDirty }: DetailsBlockProps) {
  const [formData] = useFormContext()
  const [shortTitleCharacterAmount, setShortTitleCharacterAmount] = React.useState(defaultData?.shortTitle?.length ?? 0)
  const [shortDescriptionCharacterAmount, setShortDescriptionCharacterAmount] = React.useState(defaultData?.shortDescription?.length ?? 0)

  return (
    <BlockWrapper id="experience_details">
      <BlockTitle>Experience details</BlockTitle>
      <FormField className="w-full gap-1 flex flex-col" name="title">
        <SoraFormLabel>Title</SoraFormLabel>
        <FormControl asChild>
          <TextField placeholder="Title" defaultValue={defaultData?.title} error={!!errors?.title} readOnly={!canUserEditAllFields} />
        </FormControl>
        {errors?.title && <SoraFormMessage>Title is required</SoraFormMessage>}
      </FormField>
      <FormField className="w-full gap-1 flex flex-col" name="short_title">
        <SoraFormLabel>
          <div className="flex justify-between w-full">
            <span>Short Title</span>
            <span className={`font-medium ${shortTitleCharacterAmount > 80 ? 'text-danger' : ''}`}>{shortTitleCharacterAmount}/80</span>
          </div>
        </SoraFormLabel>
        <FormControl asChild>
          <TextField placeholder="Short title" defaultValue={defaultData?.shortTitle} error={!!errors?.short_title} readOnly={!canUserEditAllFields} maxLength={80} onChange={(e) => setShortTitleCharacterAmount(e.target.value.length)} />
        </FormControl>
        {errors?.title && <SoraFormMessage>Short title is required</SoraFormMessage>}
      </FormField>
      <FormField className="w-full gap-1 flex flex-col" name="short_description">
        <SoraFormLabel>
          <div className="flex justify-between w-full">
            <span>Short Description</span>
            <span className={`font-medium ${shortDescriptionCharacterAmount > 500 ? 'text-danger' : ''}`}>{shortDescriptionCharacterAmount}/500</span>
          </div>
        </SoraFormLabel>
        <FormControl asChild>
          <textarea
            readOnly={!canUserEditAllFields}
            placeholder="short description"
            defaultValue={defaultData?.shortDescription}
            onChange={(e) => setShortDescriptionCharacterAmount(e.target.value.length)}
            maxLength={500}
            className={cn('resize-none overflow-y-auto h-48 border rounded-lg', errors?.short_description ? 'border-danger-40' : 'border-gray-40', !canUserEditAllFields ? 'cursor-not-allowed bg-gray-5 text-gray-60' : '')} />
        </FormControl>
        {errors?.short_description && <SoraFormMessage>Short Description is required and must have less than 500 characters.</SoraFormMessage>}
      </FormField>
      <div className="w-full gap-1 flex flex-col">
        <p className="text-sm font-bold">Description</p>
        <SlateTextarea
          /* @ts-ignore: Unreachable code error */
          noAttachments
          onChange={setFormDirty}
          value={defaultData?.rte_description}
          readOnly={!canUserEditAllFields}
          id="rte_description"
          aria-label="rte_description"
          name="rte_description"
          className="h-48"
        />
        {errors?.rte_description && <SoraFormMessage>Description is required</SoraFormMessage>}
      </div>
      <FileUploader urlFieldName="image_url" label="Cover Image" defaultValue={defaultData?.image_url ?? ''} error={errors?.image_url && 'Cover image is required'} readOnly={!canUserEditAllFields} name="image_file" accept="image/*" onChangeCallback={setFormDirty}>
        <FileUploader.Title>Upload image</FileUploader.Title>
        <FileUploader.Description>Upload any image file type. File size no more than 5MB.</FileUploader.Description>
        <FileUploader.Actions>
          {!canUserEditAllFields && <Button variant="outlined" size="sm" asChild><p>Upload file</p></Button>}
        </FileUploader.Actions>
      </FileUploader>
      <FormField className="w-full gap-1 flex flex-col" name="image_alt">
        <SoraFormLabel>Cover image alternative text</SoraFormLabel>
        <FormControl asChild >
          <TextField placeholder="Enter cover image alternative text" defaultValue={defaultData?.image_alt} error={!!errors?.image_alt} readOnly={!canUserEditAllFields} />
        </FormControl>
        <FormDescription>Provide a detailed description of the image, including all details required for someone to grasp its content solely through the description.</FormDescription>
        {errors?.image_alt && <SoraFormMessage>Cover image alternative text is required</SoraFormMessage>}
      </FormField>
      {
        formData?.type === 'activity'
          ? <input type="hidden" name="video_section_video_url" value={null} />
          : (
            <FormField className="w-full gap-1 flex flex-col" name="video_section_video_url">
              <SoraFormLabel>Expedition introduction video URL</SoraFormLabel>
              <FormControl asChild type="url" pattern="https://.*">
                <TextField placeholder="Paste video URL here" defaultValue={defaultData?.video_section_video_url} error={!!errors?.video_section_video_url} readOnly={!canUserEditAllFields} />
              </FormControl>
              {errors?.video_section_video_url && <SoraFormMessage>Video URL is required</SoraFormMessage>}
            </FormField>
          )
      }
    </BlockWrapper >
  )
}

interface EssentialQuestionsBlockProps {
  canUserEditAllFields: boolean,
  setFormDirty: () => void,
  defaultData?: { key_features_essential_questions_description: any[] },
  errors?: Pick<ActionData['errors'], 'key_features_essential_questions_description'>
}

function EssentialQuestionsBlock({ defaultData, errors, canUserEditAllFields, setFormDirty }: EssentialQuestionsBlockProps) {
  const essentialQuestionsRef = React.useRef(null)
  return (
    <BlockWrapper id="essential_questions">
      <BlockTitle>Essential Questions</BlockTitle>
      <BlockContent>
        <div className="w-full gap-1 flex flex-col">
          <p className="text-sm font-bold">Essential questions description</p>
          <SlateTextarea
            /* @ts-ignore: Unreachable code error */
            noAttachments
            onChange={setFormDirty}
            value={defaultData?.key_features_essential_questions_description}
            readOnly={!canUserEditAllFields}
            id="key_features_essential_questions_description"
            name="key_features_essential_questions_description"
            ref={essentialQuestionsRef}
            aria-label="key_features_essential_questions_description"
            placeholder="Type description here"
            className="h-48"
          />
          {errors?.key_features_essential_questions_description && <SoraFormMessage>Essential questions description is required</SoraFormMessage>}
        </div>
      </BlockContent>
    </BlockWrapper>
  )
}

interface MaterialsAndToolsBlockProps {
  defaultData?: MaterialsAndTools[]
  materialTypes: LabelValue[],
  setFormDirty: () => void,
  errors?: Pick<ActionData['errors'], 'materials'>
}

function MaterialsAndToolsBlock({ defaultData, materialTypes, errors, setFormDirty }: MaterialsAndToolsBlockProps) {
  const [materials, setMaterials] = React.useState<MaterialsAndTools[]>(
    defaultData ??
    []
  )

  const handleAddMaterial = () => {
    setMaterials((prevState) => {
      return prevState.length > 0
        ? [...prevState, { id: uniqueId(), title: '', description: '', type: '' }]
        : [{ id: uniqueId(), title: '', description: '', type: '' }]
    })
  }

  const handleRemoveMaterial = (id: string) => {
    setMaterials((prevState) => {
      return [...prevState.filter((material) => material.id !== id)]
    })
  }

  return (
    <BlockWrapper id="materials_and_tools">
      <BlockTitle>Materials and Tools</BlockTitle>
      <BlockContent className="gap-10">
        {materials.map((material, index) => {
          return (
            <div className="flex flex-col gap-8" key={material.id}>
              <div className="flex w-full justify-between items-center">
                <BlockTitle>Material {index + 1}</BlockTitle>
                <Button variant="ghost" color="danger" size="sm" type="button" onClick={() => handleRemoveMaterial(material.id)}>
                  <Icon name="trash" size="xs"></Icon>
                  <span>Remove material</span>
                </Button>
              </div>
              <FormField className="w-full gap-1 flex flex-col" name={`materials[${index}][type]`}>
                <SoraFormLabel>Material type</SoraFormLabel>
                <FormControl asChild >
                  <Select defaultValue={material.type}>
                    <Select.Trigger className={errors?.materials?.[index]?.type ? 'border-danger-40' : ''}>
                      <Select.Value placeholder="Select material type"></Select.Value>
                    </Select.Trigger>
                    <Select.Content>
                      {materialTypes.map((type) => (
                        <Select.Item key={type.value} value={type.value}>
                          {type.label}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                </FormControl>
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`materials[${index}][title]`}>
                <SoraFormLabel>Material title</SoraFormLabel>
                <FormControl asChild>
                  <TextField defaultValue={material.title} error={!!errors?.materials?.[index]?.title} />
                </FormControl>
                {errors?.materials?.[index]?.title && <SoraFormMessage>Title is required</SoraFormMessage>}
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`materials[${index}][description]`}>
                <SoraFormLabel>Material description</SoraFormLabel>
                <SlateTextarea
                  /* @ts-ignore: Unreachable code error */
                  noAttachments
                  onChange={setFormDirty}
                  id={`material${material.id}_description`}
                  value={material.description}
                  name={`materials[${index}][description]`}
                  aria-label={`material${material.id}_description`}
                  placeholder="Type material description here"
                  className="h-48"
                />
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`materials[${index}][link]`}>
                <SoraFormLabel>Material link (optional)</SoraFormLabel>
                <FormControl asChild type="url" pattern="https://.*" >
                  <TextField defaultValue={material.link} />
                </FormControl>
              </FormField>
            </div>
          )
        })}
        <span className="self-center">
          <Button onClick={handleAddMaterial} type="button">Add Material</Button>
        </span>
      </BlockContent>
    </BlockWrapper>
  )
}

interface LearningSectionsBlockProps {
  defaultData?: MaterialsAndTools[],
  setFormDirty: () => void,
  errors?: Pick<ActionData['errors'], 'sections'>
}

function LearningSectionsBlock({ defaultData, errors, setFormDirty }: LearningSectionsBlockProps) {
  const [section, setSections] = React.useState<LearningSection[]>(
    defaultData ??
    [{
      id: uniqueId(),
      title: '',
      description: '',
    }]
  )

  const handleAddSection = () => {
    setSections((prevState) => {
      return prevState.length > 0
        ? [...prevState, { id: uniqueId(), title: '', description: '' }]
        : [{ id: uniqueId(), title: '', description: '', }]
    })
  }

  const handleRemoveSection = (id: string) => {
    setSections((prevState) => {
      return [...prevState.filter((section) => section.id !== id)]
    })
  }

  return (
    <BlockWrapper id="learning_section">
      <BlockTitle>Six Week Plan</BlockTitle>
      <BlockContent className="gap-10">
        {section.map((section, index) => {
          return (
            <div className="flex flex-col gap-8" key={section.id}>
              <input type='hidden' name={`sections[${index}][index]`} value={index} />
              <div className="flex w-full justify-between items-center">
                <BlockTitle>Week {index + 1}</BlockTitle>
                <Button variant="ghost" color="danger" size="sm" type="button" onClick={() => handleRemoveSection(section.id)}>
                  <Icon name="trash" size="xs"></Icon>
                  <span>Remove week</span>
                </Button>
              </div>
              <FormField className="w-full gap-1 flex flex-col" name={`sections[${index}][title]`}>
                <SoraFormLabel>Week title</SoraFormLabel>
                <FormControl asChild>
                  <TextField defaultValue={section.title} error={!!errors?.sections?.[index]?.title} />
                </FormControl>
                {errors?.sections?.[index]?.title && <SoraFormMessage>Title is required</SoraFormMessage>}
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`sections[${index}][description]`}>
                <SoraFormLabel>Week description</SoraFormLabel>
                <SlateTextarea
                  /* @ts-ignore: Unreachable code error */
                  onChange={setFormDirty}
                  noAttachments
                  id={`section${section.id}_description`}
                  name={`sections[${index}][description]`}
                  aria-label={`section${section.id}_description`}
                  placeholder="Type section description here"
                  value={section.description}
                  className="h-48"
                />
              </FormField>
            </div>
          )
        })}
        <span className="self-center">
          <Button type="button" onClick={handleAddSection}>Add Week</Button>
        </span>
      </BlockContent>
    </BlockWrapper>
  )
}

function FormFooter() {
  const navigation = useNavigation()
  const isSubmitting = navigation.state === 'submitting'

  return (
    <div className="bg-white border-t border-t-1 items-center border-gray-40 px-40 h-16 w-full flex flex-row justify-end fixed bottom-0 left-0 right-0 z-60">
      <div className="space-x-2 flex items-center">
        <Button
          asChild
          variant="outlined"
          size="sm"
        >
          <Link to="../../template/library">
            Cancel
          </Link>
        </Button>
        <Button
          type="submit"
          size="sm"
          disabled={isSubmitting}
        >
          {isSubmitting ? <Icon size="sm" name="loader" className="animate-spin" /> : 'Save'}
        </Button>
      </div>
    </div>
  )
}

interface FinalDeliverableBlockProps {
  canUserEditAllFields: boolean,
  units: LabelValueBySchoolStage,
  abilities: LabelValueBySchoolStage,
  taskTypes: (LabelValue & { tooltipContent: string })[],
  setFormDirty: () => void,
  defaultData?: {
    id: string,
    title: string,
    rte_description: any[],
    sequenceNumber?: string,
    type: Pick<Session['tasks'][0], 'type'>['type']
  },
  errors?: Pick<ActionData['errors'], 'final_deliverable'>
}

function FinalDeliverableBlock({ defaultData, units, abilities, errors, canUserEditAllFields, setFormDirty, taskTypes }: FinalDeliverableBlockProps) {
  const [formData] = useFormContext()
  const isExtracurricularExperience = formData?.type === 'extracurricular'
  const [isFinalDeliverableVisible, setIsFinalDeliverableVisible] = React.useState(isExtracurricularExperience && !defaultData ? false : true)

  const sessionsOptions = Array.from({ length: formData?.sessions?.length }, (_, i) => i + 1).map((session) => {
    return { value: session.toString(), label: `Session ${session}` }
  })
  sessionsOptions.unshift({ value: '-1', label: 'None' })

  const currentUnits = useSchoolStageBasedValue(units)
  const currentAbilities = useSchoolStageBasedValue(abilities)
  const { selectedItems: finalReviewUnits } = useFormControlledCombobox({ values: currentUnits, formKey: 'units' })
  const { selectedItems: finalReviewAbilities } = useFormControlledCombobox({ values: currentAbilities, formKey: 'abilities' })

  React.useEffect(() => {
    if (formData?.type === 'extracurricular' && !defaultData) {
      setIsFinalDeliverableVisible(false)
    }
  }, [formData?.type])


  return (
    <BlockWrapper id="final_project" unstyled>
      {isFinalDeliverableVisible && (
        <BlockWrapper>
          {!isExtracurricularExperience ?
            <BlockTitle>Final project</BlockTitle>
            : (
              <div className="flex w-full justify-between items-center">
                <BlockTitle>Final Project</BlockTitle>
                <Button variant="ghost" color="danger" size="sm" type="button" onClick={() => setIsFinalDeliverableVisible(false)}>
                  <Icon name="trash" size="xs"></Icon>
                  <span>Remove</span>
                </Button>
              </div>
            )
          }
          <BlockContent>
            <FormField className="w-full gap-1 flex flex-col" name="final_deliverable[title]">
              <SoraFormLabel>Final project title</SoraFormLabel>
              <FormControl asChild>
                {/** @ts-ignore: can't type the zod error object deeply, so we use unknown and this may cause typing errors like the one on the line below */}
                <TextField placeholder="Enter final deliverable title" defaultValue={defaultData?.title} error={!!errors?.final_deliverable?.title} readOnly={!canUserEditAllFields} />
              </FormControl>
              {/** @ts-ignore: can't type the zod error object deeply, so we use unknown and this may cause typing errors like the one on the line below */}
              {errors?.final_deliverable?.title && <SoraFormMessage>Title is required</SoraFormMessage>}
            </FormField>
            <FormField className="w-full gap-1 flex flex-col" name="final_deliverable[sequence_number]">
              <SoraFormLabel>Attach to session</SoraFormLabel>
              <FormControl asChild>
                <Select defaultValue={defaultData?.sequenceNumber?.toString() ?? '-1'} disabled={!canUserEditAllFields}>
                  <Select.Trigger>
                    <Select.Value placeholder="Attach to session"></Select.Value>
                  </Select.Trigger>
                  <Select.Content>
                    {sessionsOptions.map((session) => (
                      <Select.Item key={session.value} value={session.value}>
                        {session.label}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              </FormControl>
            </FormField>
            <FormField className="w-full gap-1 flex flex-col" name="final_deliverable[rte_description]">
              <SoraFormLabel>Task Description</SoraFormLabel>
              <SlateTextarea
                /* @ts-ignore: Unreachable code error */
                noAttachments
                onChange={setFormDirty}
                value={defaultData?.rte_description}
                id="final_deliverable_description"
                readOnly={!canUserEditAllFields}
                aria-label="final_deliverable_description"
                name="final_deliverable[rte_description]"
                className="h-48"
              />
            </FormField>
            <TaskTypeRadioGroup defaultValue={defaultData?.type} name="final_deliverable[type]" taskTypes={taskTypes} />
            <div className="grid grid-cols-2 gap-6">
              <div className="flex flex-col">
                <h5 className="font-bold">Units</h5>
                <p className="text-sm">All units added to the Experience settings must be covered in the Final project.</p>
                <div className="flex gap-2 flex-wrap mt-3">
                  {finalReviewUnits?.map((unit) => (
                    <Pill key={unit.value} className="max-w-40 2xl:max-w-fit lg:whitespace-normal">
                      <Pill.Value className="truncate">{unit.label}</Pill.Value>
                    </Pill>
                  ))}
                </div>
              </div>
              <div className="flex flex-col">
                <h5 className="font-bold">Abilities</h5>
                <p className="text-sm">All ablities added to the Experience settings must be covered in the Final project.</p>
                <div className="flex flex-wrap gap-2 mt-3">
                  {finalReviewAbilities?.map((ability) => (
                    <Pill key={ability.value} className="max-w-40 2xl:max-w-fit lg:whitespace-normal">
                      <Pill.Value className="truncate">{ability.label}</Pill.Value>
                    </Pill>
                  ))}
                </div>
              </div>
            </div>
          </BlockContent>
        </BlockWrapper>
      )}
      {!isFinalDeliverableVisible && (
        <div className="flex px-8 py-4 border-2 border-dashed border-gray-40 rounded-lg justify-center items-center">
          <Button onClick={() => setIsFinalDeliverableVisible(true)} variant="ghost" type="button">Add Final Deliverable</Button>
        </div>
      )}
    </BlockWrapper >
  )
}

interface FilterPillProps {
  values: LabelValue[],
  removeable?: boolean,
  onRemove: (id: string) => void,
}

function FilterPills({ values, removeable = true, onRemove }: FilterPillProps) {
  return (
    <div className="flex flex-wrap gap-2 mt-3">
      {values.map((value) => (
        <Pill key={value.value} className="max-w-40 lg:max-w-52 2xl:max-w-fit">
          <Pill.Value className="truncate">{value.label}</Pill.Value>
          {removeable && <button onClick={() => onRemove(value.value)}><Pill.Icon name="cross" /></button>}
        </Pill>
      ))}
    </div>
  )
}

interface ProjectsBlockProps {
  canUserEditAllFields: boolean,
  units: LabelValueBySchoolStage,
  abilities: LabelValueBySchoolStage,
  defaultData: Project[],
  setFormDirty: () => void,
  errors: Pick<ActionData['errors'], 'projects'>
}

function ProjectsBlock({ units, abilities, defaultData, errors, canUserEditAllFields, setFormDirty }: ProjectsBlockProps) {
  const [formData] = useFormContext()
  const [projects, setProjects] = React.useState<Project[]>(defaultData ?? [])
  const currentUnits = useSchoolStageBasedValue(units)

  const templateUnitIds = formData?.units?.split(',') ?? []
  const projectAvailableUnits = currentUnits?.filter((unit) => !templateUnitIds?.includes(unit.value))
  const notSelectedUnits = projectAvailableUnits

  const handleAddUnit = (projectId: string) => (unitId: string) => {
    setProjects((prevState) => {
      return [...prevState.map((project) => {
        if (project.id === projectId) {
          const units = project.units
          units.push(unitId)
          return {
            ...project,
            units: units
          }
        }
        return project
      })]
    })
  }

  const handleRemoveUnit = (projectId: string) => (unitId: string) => {
    setProjects((prevState) => {
      return [...prevState.map((project) => {
        if (project.id === projectId) {
          const units = project.units.filter((pUnit) => pUnit !== unitId)
          return {
            ...project,
            units: units
          }
        }
        return project
      })]
    })
  }

  const currentAbilities = useSchoolStageBasedValue(abilities)
  const templateAbilityIds = formData?.abilities?.split(',') ?? []
  const projectAvailableAbilities = currentAbilities.filter((ability) => !templateAbilityIds?.includes(ability.value))
  const notSelectedAbilities = projectAvailableAbilities

  const handleAddAbility = (projectId: string) => (abilityId: string) => {
    setProjects((prevState) => {
      return [...prevState.map((project) => {
        if (project.id === projectId) {
          const abilities = project.abilities
          abilities.push(abilityId)
          return {
            ...project,
            abilities: abilities
          }
        }
        return project
      })]
    })
  }

  const handleRemoveAbility = (projectId: string) => (abilityId: string) => {
    setProjects((prevState) => {
      return [...prevState.map((project) => {
        if (project.id === projectId) {
          const abilities = project.abilities.filter((pUnit) => pUnit !== abilityId)
          return {
            ...project,
            abilities: abilities
          }
        }
        return project
      })]
    })
  }


  const handleAddProject = () => {
    setProjects((prevState) => {
      return prevState.length > 0
        ? [...prevState, { id: uniqueId(), title: '', description: '', units: [], abilities: [] }]
        : [{ id: uniqueId(), title: '', description: '', units: [], abilities: [] }]
    })
  }

  const handleRemoveProject = (id: string) => {
    setProjects((prevState) => {
      return [...prevState.filter((project) => project.id !== id)]
    })
  }

  return (
    <BlockWrapper id="projects">
      <BlockTitle>Projects</BlockTitle>
      <BlockContent className="gap-10">
        {projects.map((project, index) => {
          return (
            <div className="flex flex-col gap-8" key={project.id}>
              <div className="flex w-full justify-between items-center">
                <BlockTitle>Project {index + 1}</BlockTitle>
                <Button variant="ghost" color="danger" size="sm" type="button" disabled={!canUserEditAllFields} onClick={() => handleRemoveProject(project.id)}>
                  <Icon name="trash" size="xs"></Icon>
                  <span>Remove project</span>
                </Button>
              </div>
              <BlockContent>
                <FormField className="w-full gap-1 flex flex-col" name={`projects[${index}][title]`}>
                  <SoraFormLabel>Project title</SoraFormLabel>
                  <FormControl asChild>
                    <TextField defaultValue={project.title} error={!!errors?.projects?.[index]?.title} readOnly={!canUserEditAllFields} />
                  </FormControl>
                </FormField>
                {errors?.projects?.[index]?.title && <SoraFormMessage>Project title is required</SoraFormMessage>}
                <FormField className="w-full gap-1 flex flex-col" name={`projects[${index}][description]`}>
                  <SoraFormLabel>Project description</SoraFormLabel>
                  <SlateTextarea
                    /* @ts-ignore: Unreachable code error */
                    noAttachments
                    onChange={setFormDirty}
                    value={project?.description}
                    readOnly={!canUserEditAllFields}
                    id={`project${project.id}_description`}
                    name={`projects[${index}][description]`}
                    aria-label={`project${project.id}_description`}
                    placeholder="Type project description here"
                    className="h-48"
                  />
                </FormField>
                <div className="grid grid-cols-2 gap-6">
                  <FormField className="w-full gap-1 flex flex-col" name={`projects[${index}][units]`}>
                    <SoraFormLabel>Project units</SoraFormLabel>
                    <input type="hidden" name={`projects[${index}][units]`} value={project.units.toString()} />
                    <Combobox options={notSelectedUnits} placeholder="Type to search for units" onChange={handleAddUnit(project.id)} disabled={!canUserEditAllFields}></Combobox>
                    <FormDescription>All the core units will also be covered in a project.</FormDescription>
                    <FilterPills values={currentUnits.filter((unit) => project.units.includes(unit.value))} onRemove={handleRemoveUnit(project.id)} removeable={canUserEditAllFields} />
                  </FormField>
                  <FormField className="w-full gap-1 flex flex-col" name={`projects[${index}][abilities]`}>
                    <SoraFormLabel>Project abilities</SoraFormLabel>
                    <input type="hidden" name={`projects[${index}][abilities]`} value={project.abilities.toString()} />
                    <Combobox options={notSelectedAbilities} placeholder="Type to search for abilities" onChange={handleAddAbility(project.id)} disabled={!canUserEditAllFields}></Combobox>
                    <FormDescription>All the core abilities will also be covered in a project.</FormDescription>
                    <FilterPills values={currentAbilities.filter((ability) => project.abilities.includes(ability.value))} onRemove={handleRemoveAbility(project.id)} removeable={canUserEditAllFields} />
                  </FormField>
                </div>
              </BlockContent>
            </div>
          )
        })}
        <span className="self-center">
          <Button type="button" onClick={handleAddProject} disabled={!canUserEditAllFields}>Add Project</Button>
        </span>
      </BlockContent>
    </BlockWrapper>
  )
}

interface SessionsBlockProps {
  setFormDirty: () => void,
  taskTypes: (LabelValue & { tooltipContent: string })[],
  defaultData?: Session[]
}

function SessionsBlock({ defaultData, setFormDirty, taskTypes }: SessionsBlockProps) {
  const [_, setFormData] = useFormContext()
  const [sessions, setSessions] = React.useState<Session[]>(defaultData ?? [])

  React.useEffect(() => {
    const templateInput = document.querySelector('input[name="title"]') as HTMLInputElement
    const handleTitleChange = (e) => {
      setSessions((prevState) => {
        if (prevState.length === 0) return [...prevState]
        return [...prevState.map((session) => {
          const sessionIndex = session.title.split(' ')[1]
          return {
            ...session,
            title: `Session ${sessionIndex} - ${e.target.value}`,
          }
        })]
      })
    }
    templateInput.addEventListener('input', handleTitleChange)
    return () => {
      templateInput.removeEventListener('input', handleTitleChange)
    }
  }, [])

  const handleAddSession = () => {
    const templateTitle = (document.querySelector('input[name="title"]') as HTMLInputElement)?.value
    setSessions((prevState) => {
      const newItem = prevState.length > 0 ? {
        id: uniqueId(),
        title: `Session ${prevState.length + 1} - ${templateTitle}`,
        rte_description: null,
      }
        : {
          id: uniqueId(),
          title: `Session 1 - ${templateTitle}`,
          rte_description: null,
        }
      setFormData((prevState) => {
        return {
          ...prevState,
          sessions: [...prevState?.sessions || [], newItem]
        }
      })
      return prevState.length > 0
        ? [...prevState, newItem]
        : [newItem]
    })
  }

  const handleDeleteSession = (id: string) => {
    setSessions((prevState) => {
      const state = [...prevState.filter(s => s.id !== id)]
      setFormData((prevState) => {
        return {
          ...prevState,
          sessions: [...prevState?.sessions.filter(s => s.id !== id)]
        }
      })
      return state.map((session, index) => {
        return {
          ...session,
          title: `Session ${index + 1} - ${session.title.split(' - ')[1]}`,
        }
      })
    })
  }

  const handleAddTask = (sessionId: string) => {
    setSessions((prevState) => {
      return prevState.map((session) => {
        if (session.id === sessionId) {
          return {
            ...session,
            tasks: session.tasks
              ? [...session.tasks, { id: uniqueId(), title: '', rte_description: null, readOnly: false, type: 'regular' }]
              : [{ id: uniqueId(), title: '', rte_description: null, readOnly: false, type: 'regular' }],
          }
        }
        return session
      })
    })
  }

  const handleDeleteTask = (sessionId: string, taskId: string) => {
    setSessions((prevState) => {
      return prevState.map((session) => {
        if (session.id === sessionId) {
          return {
            ...session,
            tasks: session.tasks?.filter((task) => task.id !== taskId),
          }
        }
        return session
      })
    })
  }

  return (
    <BlockWrapper id="sessions" unstyled>
      {sessions.map((session, index) => {
        return (
          <BlockWrapper key={session.id}>
            <div className="flex w-full justify-between items-center">
              <BlockTitle>{session.title}</BlockTitle>
              <Button variant="ghost" color="danger" size="sm" type="button" onClick={() => handleDeleteSession(session.id)}>
                <Icon name="trash" size="xs"></Icon>
                <span>Remove session</span>
              </Button>
            </div>
            <BlockContent>
              <FormField className="w-full gap-1 flex flex-col" name={`sessions[${index}][title]`}>
                <SoraFormLabel>Session title</SoraFormLabel>
                <FormControl asChild >
                  <TextField key={`${session.id}_${index}`} value={session.title} readOnly />
                </FormControl>
              </FormField>
              <FormField className="w-full gap-1 flex flex-col" name={`sessions[${index}][rte_description]`}>
                <SoraFormLabel>Session description</SoraFormLabel>
                <SlateTextarea
                  /* @ts-ignore: Unreachable code error */
                  onChange={setFormDirty}
                  noAttachments
                  value={session?.rte_description}
                  id={`session${session.id}_description`}
                  name={`sessions[${index}][rte_description]`}
                  aria-label={`session${session.id}_description`}
                  placeholder="Type session description here"
                  className="h-48"
                />
                <FormDescription>Describe the intended objective of the session, guiding experts when planning how to run this experience.</FormDescription>
              </FormField>
              {session.tasks?.length > 0 ?
                session.tasks?.map((task, tIndex) => {
                  return (
                    <React.Fragment key={task.id}>
                      <div className="flex w-full justify-between items-center">
                        <BlockTitle>Task</BlockTitle>
                        <Button variant="ghost" color="danger" size="sm" type="button" onClick={() => handleDeleteTask(session.id, task.id)}>
                          <Icon name="trash" size="xs"></Icon>
                          <span>Remove task</span>
                        </Button>
                      </div>
                      <BlockContent>
                        <FormField className="w-full gap-1 flex flex-col" name={`sessions[${index}][tasks][${tIndex}][title]`}>
                          <SoraFormLabel>Task title</SoraFormLabel>
                          <FormControl asChild >
                            <TextField defaultValue={task.title} />
                          </FormControl>
                        </FormField>
                        <FormField className="w-full gap-1 flex flex-col" name={`sessions[${index}][tasks][${tIndex}][rte_description]`}>
                          <SoraFormLabel>Task description</SoraFormLabel>
                          <SlateTextarea
                            /* @ts-ignore: Unreachable code error */
                            onChange={setFormDirty}
                            noAttachments
                            id={`task${task.id}_description`}
                            value={task?.rte_description}
                            name={`sessions[${index}][tasks][${tIndex}][rte_description]`}
                            aria-label={`task${task.id}_description`}
                            placeholder="Type task description here"
                            className="h-48"
                          />
                        </FormField>
                        <TaskTypeRadioGroup defaultValue={task.type} name={`sessions[${index}][tasks][${tIndex}][type]`} taskTypes={taskTypes} />
                      </BlockContent>
                    </React.Fragment>
                  )
                })
                : null}
              <span className="self-center">
                <Button type="button" onClick={() => handleAddTask(session.id)}>Add Task</Button>
              </span>
            </BlockContent>
          </BlockWrapper>
        )
      })}
      <div className="flex px-8 py-4 border-2 border-dashed border-gray-40 rounded-lg justify-center items-center">
        <Button onClick={handleAddSession} variant="ghost" type="button">Add Session</Button>
      </div>
    </BlockWrapper>
  )
}

function SideNavigator() {
  const items = [
    { id: 'template_settings', text: 'Experience Settings' },
    { id: 'interest_tags', text: 'Interest Tags' },
    { id: 'units', text: 'Units' },
    { id: 'abilities', text: 'Abilities' },
    { id: 'instructor_notes', text: 'Instructor Notes' },
    { id: 'projects', text: 'Projects' },
    { id: 'experience_details', text: 'Experience Details' },
    { id: 'essential_questions', text: 'Essential Questions' },
    { id: 'learning_objectives', text: 'Learning Objectives' },
    { id: 'materials_and_tools', text: 'Materials And Tools' },
    { id: 'learning_section', text: 'Six Week Plan' },
    { id: 'guidebook', text: 'Expedition Guide' },
    { id: 'sessions', text: 'Sessions' },
    { id: 'final_project', text: 'Final Project' }
  ]

  const scrollToElementWithId = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }

  return (
    <aside className="w-60">
      <div className="fixed top-[9.5rem] left-[calc(1rem+var(--aside-width))] bottom-0 h-[75svh] short:h-[65svh] overflow-y-auto">
        <ul className="space-y-4 h-full pb-16">
          {items.map((item) => (
            <li
              key={item.id}
              onClick={() => scrollToElementWithId(item.id)}
              className="cursor-pointer md:block hidden max-w-24 lg:max-w-full truncate md:w-full py-2 md:px-0 lg:px-4 self-start text-md rounded-lg font-semibold hover:font-bold hover:bg-gray-20"
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>
    </aside>
  )
}

type SchoolStage = 'ms' | 'hs'
type LabelValueBySchoolStage = Record<SchoolStage, LabelValue[]>

function useSchoolStageBasedValue(valueBySchoolStage: LabelValueBySchoolStage) {
  const [values, setValues] = React.useState(valueBySchoolStage?.hs ?? [])
  const [formData] = useFormContext()

  React.useEffect(() => {
    if (formData?.school_stage) {
      setValues(valueBySchoolStage[formData.school_stage])
    }
  }, [formData?.school_stage])

  return values
}


interface GoogleDriveFileProps {
  file: Guidebook,
  onDismiss?: () => void,
  navigateToUrl?: boolean,
}

function GoogleDriveFile({ file, onDismiss, navigateToUrl }: GoogleDriveFileProps) {
  const title = <Typography variant='body' weight='bold'>
    {file.name}
  </Typography>

  return <div className='p-2 rounded-lg border border-gray-50 flex'>
    <div className='p-4 rounded-lg bg-gray-10 flex-none'>
      <div className='size-6'>
        <img src="/assets/google-document.svg" className='w-6 h-6' alt="Document" />
      </div>
    </div>
    <div className='flex-1 content-center ps-4'>
      {navigateToUrl ? <a href={file.url} target="_blank">
        {title}
      </a> : title}
      <Typography variant='footnote'>
        Google Docs
      </Typography>
    </div>
    {onDismiss && (
      <div className='flex-none content-center'>
        <Button
          variant='ghost'
          onClick={onDismiss}>
          <Icon name='cross' />
        </Button>
      </div>
    )}
  </div>
}

interface GuidebookBlockProps {
  defaultData?: Guidebook,
  guidebooksGoogleDriveFolder: string,
}

function GuidebookBlock({ defaultData, guidebooksGoogleDriveFolder }: GuidebookBlockProps) {
  const [selectedFile, setSelectedFile] = React.useState(defaultData)

  return (
    <BlockWrapper id="guidebook">
      <BlockTitle>Expedition Guide</BlockTitle>
      <BlockContent>
        <input type="hidden" name="guidebook_document_id" value={selectedFile?.id ?? ''} />
        <input type="hidden" name="guidebook_document_url" value={selectedFile?.url ?? ''} />
        <input type="hidden" name="guidebook_document_name" value={selectedFile?.name ?? ''} />
        <Typography variant="callout">
          Each student will get their own copy of the file with their name added to the document title. They will be able to access it from each individual task that uses the expedition guide.
        </Typography>
        <Typography variant="callout" weight="bold">
          Important: Make sure your documents are in the <a className='hover:underline text-accent' target='_blank' href={`https://drive.google.com/drive/folders/${guidebooksGoogleDriveFolder}`}>guidebooks folder</a> before you insert them.
        </Typography>
        <div>
          {
            selectedFile ?
              <GoogleDriveFile file={selectedFile} navigateToUrl={true} onDismiss={() => setSelectedFile(null)}
              /> :
              <Dialog>
                <Dialog.Trigger asChild>
                  <Button variant="outlined" color="soft" type="button">
                    <img src="/assets/google-drive.svg" className='w-6 h-6' alt="Google Drive" />
                    <span>Insert from Google Drive</span>
                  </Button>
                </Dialog.Trigger>
                <GuidebookDialogContent
                  defaultData={selectedFile}
                  setSelectedFile={setSelectedFile}
                />
              </Dialog>
          }
        </div>
      </BlockContent>
    </BlockWrapper>
  )
}

interface GuidebookDialogContentProps {
  defaultData?: Guidebook,
  setSelectedFile: (file: Guidebook) => void,
}

function GuidebookDialogContent({ defaultData, setSelectedFile }: GuidebookDialogContentProps) {
  const { isOpen } = Dialog.useDialog()
  if (!isOpen) {
    return null
  }

  return (
    <GuidebookDialogOpenedContent
      defaultData={defaultData}
      setSelectedFile={setSelectedFile}
    />
  )
}

function GuidebookDialogOpenedContent({ defaultData, setSelectedFile }: GuidebookDialogContentProps) {
  const { closeDialog } = Dialog.useDialog()
  const [rootFolder, setRootFolder] = React.useState<GuidebookFolder>(null)
  const [currentFolderState, setCurrentFolderState] = React.useState(null)
  const { mutate: refreshGuidebooks } = useSWR('/backoffice/experiences/guidebooks', {
    onSuccess: (data) => {
      setRootFolder(data)
      setCurrentFolderState(data)
    }
  })
  const [dialogSelectedFile, setDialogSelectedFile] = React.useState(defaultData)
  const searchInputRef = React.useRef(null)
  const backToRootFolder = React.useCallback(() => {
    setCurrentFolderState(rootFolder)
  }, [rootFolder])
  const handleClearSearch = React.useCallback(() => {
    searchInputRef.current.value = ''
    backToRootFolder();
  }, [searchInputRef, rootFolder])

  const setSelectedFolder = React.useCallback((folder: GuidebookFolder) => {
    setDialogSelectedFile(null)
    setCurrentFolderState(folder)
  }, [currentFolderState])
  React.useEffect(() => {
    function setParentFolderForChildren(folder: GuidebookFolder) {
      if (!folder) {
        return
      }
      for (const childFolder of folder.childrenFolders ?? []) {
        childFolder.parentFolder = folder
        setParentFolderForChildren(childFolder)
      }
    }
    setParentFolderForChildren(rootFolder)
  }, [rootFolder])
  const folderStack: GuidebookFolder[] = React.useMemo(() => {
    function getFolderStack(folder: GuidebookFolder) {
      if (!folder) {
        return []
      }
      if (!folder.parentFolder) {
        return [folder]
      }

      return [...getFolderStack(folder.parentFolder), folder]
    }

    return getFolderStack(currentFolderState)
  }, [currentFolderState])

  return (
    <Dialog.Content className='h-[70svh]'>
      <Dialog.Header variant="outlined">
        <Dialog.Title>Insert Guidebook</Dialog.Title>
        <Dialog.Close />
      </Dialog.Header>
      {
        rootFolder ?
          <>
            <Dialog.Body className='px-8 pt-8 gap-0 overflow-y-auto h-full'>
              <TextField
                ref={searchInputRef}
                startAdornment={<Icon name="search" size="sm" />}
                placeholder="Search guidebook..."
                name='search'
                endAdornment={
                  <Button variant="ghost" size="sm" className="text-alpha/40" type="button" onClick={handleClearSearch}>
                    <Icon name="cross-circle" />
                  </Button>
                }
                autoComplete='off'
                onChange={(e: { target: { value: string } }) => {
                  const query = e.target.value.toLowerCase()
                  if (query === '') {
                    backToRootFolder();
                    return
                  }

                  function getFiles(folders: { childrenFiles?: Guidebook[], childrenFolders?: GuidebookFolder[] }[]): Guidebook[] {
                    return folders.flatMap((folder) => [
                      ...(folder.childrenFiles ?? []),
                      ...getFiles(folder.childrenFolders ?? []),
                    ])
                  }
                  const allFiles = getFiles([rootFolder])
                  setCurrentFolderState({
                    name: '...',
                    childrenFiles: allFiles.filter((guidebook) => guidebook.name.toLowerCase().includes(query)),
                    childrenFolders: [],
                  })
                }}
                fullWidth
                autoFocus
              />
              <div className='flex items-center gap-2'>
                <Typography className='my-6' variant='subheadline' weight='bold'>Select Guidebook</Typography>
                <Tooltip content='Refresh'>
                  <Button variant='ghost' size='sm' onClick={() => {
                    setRootFolder(null)
                    setSelectedFolder(null)
                    refreshGuidebooks()
                  }}>
                    <Icon name='refresh-cw' />
                  </Button>
                </Tooltip>
              </div>
              {folderStack.length > 1 ? <div className='flex mb-6'>
                {folderStack.map((folder, index) => (
                  <FolderStackItem
                    folder={folder}
                    isLast={index === folderStack.length - 1}
                    setSelectedFolder={setSelectedFolder}
                  />
                ))}
              </div> : null}
              {currentFolderState.childrenFolders?.length ?? 0 ? <div className='grid grid-cols-2 gap-4 mb-6'>
                {currentFolderState.childrenFolders.map((folder) => (
                  <div onClick={() => {
                    setSelectedFolder(folder)
                  }}
                    className='cursor-pointer rounded-lg bg-gray-10 flex px-2 py-3'>
                    <Icon name='folder' className='mr-4' />
                    <Typography variant='body' weight='bold'>{folder.name}</Typography>
                  </div>
                ))}
              </div> : null}
              {currentFolderState.childrenFiles?.map((guidebook) => (
                <div onClick={() => {
                  setDialogSelectedFile(guidebook)
                }}
                  className={`cursor-pointer flex px-2 py-3 border-b border-b-gray-30 ${dialogSelectedFile?.id === guidebook.id ? 'bg-blue-5' : ''}`}>
                  <Icon name='file-text' className='mr-4' />
                  <Typography variant='body' weight='bold'>{guidebook.name}</Typography>
                </div>
              ))}
            </Dialog.Body>
            <Dialog.Footer >
              <Button
                variant='outlined'
                onClick={() => {
                  setDialogSelectedFile(null)
                  backToRootFolder()
                  closeDialog()
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!dialogSelectedFile}
                onClick={() => {
                  setSelectedFile(dialogSelectedFile)
                  setDialogSelectedFile(null)
                  backToRootFolder()
                  closeDialog()
                }}
              >
                Insert
              </Button>
            </Dialog.Footer>
          </> :
          <Dialog.Body className='flex justify-center items-center h-full'>
            <Spinner />
          </Dialog.Body>
      }
    </Dialog.Content>
  )
}
interface FolderStackItemProps {
  folder: GuidebookFolder,
  isLast: boolean,
  setSelectedFolder: (folder: GuidebookFolder) => void,
}

function FolderStackItem({ folder, isLast, setSelectedFolder }: FolderStackItemProps) {
  return (
    <>
      <Typography
        className={!isLast ? 'cursor-pointer' : ''}
        onClick={!isLast ? () => {
          setSelectedFolder(folder)
        } : null}
        variant='subheadline'
        weight={isLast ? 'bold' : 'regular'}
      >{folder.name ?? '...'}</Typography>
      {!isLast && (
        <Icon name='chevron-right' className='mx-1' />
      )}
    </>
  )
}

NEW_TemplateFormRoute.loader = loader
NEW_TemplateFormRoute.action = action
